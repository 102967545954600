.sidebar {
  padding: 27px 10px 27px 10px;
  max-width: 285px;
  width: 100%;
  border-right: 1px solid rgba(black, 0.15);
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $media-sm) {
    max-width: 100%;
  }

  &__heading {
    font-size: 15px;
    margin-bottom: 12px;
    padding-left: 10px;
  }

  &__logo {
    margin-bottom: 22px;
    padding-left: 10px;
  }

  &__sidebar-menu-item {
    margin-bottom: 8px;
  }

  &__tasks {
    padding-bottom: 9px;
    position: relative;
    margin-bottom: 16px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 96%;
      background-color: rgba(black, 0.15);
    }
  }

  &__meetups {
    padding-bottom: 9px;
    position: relative;
    margin-bottom: 16px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 96%;
      background-color: rgba(black, 0.15);
    }
  }

  &__menu {
    padding-bottom: 9px;
    position: relative;
    margin-bottom: 16px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 96%;
      background-color: rgba(black, 0.15);
    }
  }

  &__user {
    display: flex;
    align-items: flex-end;
    flex: 1 0;
  }

  &__user-info {
    display: flex;
    align-items: center;
    padding: 8px;
    padding-left: 6px;
    border-radius: 8px;
    max-width: 257px;
    width: 100%;
    gap: 6px;
    &:hover {
      cursor: pointer;
      background-color: rgba(#2370c9, 0.15);
    }
  }

  &__user-icon {
    min-width: 24px;
  }

  &__username {
    font-weight: 700;
    font-size: 15px;
    color: #263238;
    @include text-truncate(1);
  }
}
