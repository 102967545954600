.meetups-page {
  display: flex;
  width: 100%;
  min-height: 100vh;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 32px;
    position: relative;

    @media screen and (max-width: $media-xsm) {
      padding: 0 12px;
    }
  }

  &__sidebar {
    @media screen and (max-width: $media-sm) {
      display: none;
    }
  }

  &__app-modal {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 500px;
    width: 100%;
    left: auto !important;

    .app-modal__container {
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
      width: 500px;
      height: 100%;
      background-color: white;
      padding: 24px;
      transform: none;
      box-shadow: 15px 0 40px -5px rgba(0, 0, 0, 0.3);

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__burger-menu {
    margin-right: 12px;
    display: none;

    @media screen and (max-width: $media-sm) {
      display: block;
    }
  }

  &__title-container {
    position: relative;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -32px;
      background-color: rgba(black, 0.15);
      width: calc(100% + 64px);
      height: 1px;
    }

    @media screen and (max-width: $media-sm) {
      width: calc(100vw - 64px);
    }

    @media screen and (max-width: $media-xsm) {
      width: calc(100vw - 24px);
    }
  }

  &__title-wrapper {
    display: flex;
  }

  &__title {
    font-size: 17px;
    font-weight: 500;
    @include text-truncate(1);
    word-break: break-all;
  }

  &__controls {
    display: flex;
    gap: 16px;
  }

  &__modal.MuiModal-root {
    z-index: 1303;
    overflow: hidden;

    .app-modal__container {
      padding: 0;
      max-width: 375px;
      height: 660px;
      width: 100%;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 12px;

      @media screen and (max-width: $media-sm) {
        max-width: 100%;
        height: 100%;
        top: 0;
      }
    }
  }

  &__close-envelops-form {
    & .app-modal__container {
      max-width: 375px;
      height: 620px;
      width: 100%;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 32px 24px;

      @media screen and (max-width: $media-sm) {
        position: absolute;
        top: 0;
        max-width: 100%;
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
      }
    }
  }

  &__side-menu {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 500px;
    width: 100%;
    left: auto !important;

    .app-modal__container {
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
      width: 500px;
      height: 100%;
      background-color: white;
      padding: 24px;
      transform: none;
      box-shadow: 15px 0 40px -5px rgba(0, 0, 0, 0.3);

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

  &__alphabet-btn {
    font-weight: 800;
    font-size: 11px;
    color: #263238;
    opacity: 0.9;
  }

  &__filter-icon {
    width: 22px;
    height: 22px;
  }

  &__alphabet-filter {
    font-weight: 600;
    font-size: 11px;
    line-height: 8px;
    color: #263238;
    opacity: 0.9;
  }

  &__alphabet-menu-filter {
    font-weight: 600;
    font-size: 11px;
    line-height: 8px;
    margin-right: 9px;
    color: #263238;
    opacity: 0.9;
  }

  &__filter-icon-menu {
    width: 16px;
    height: 16px;
    margin-right: 13px;
    margin-left: 3px;
  }

  &__search-button {
    & .search-button__input {
      color: #263238;
      font-size: 15px;
      font-weight: normal;
    }
  }

  &__select-button_selected {
    background-color: #2574d0;

    &:hover {
      background-color: #2574d0;
    }
  }

  &__select-icon_selected {
    fill: white !important;
  }

  &__select-icon {
    width: 16px;
    height: 16px;
  }

  &__cancel-select.MuiButtonBase-root.MuiButton-root {
    background-color: transparent;
    height: 32px;
    color: $color-action;
  }

  &__create-envelope-task-popup {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
  }
}
