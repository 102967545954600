.sidebar-menu-item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  max-width: 257px;
  width: 100%;

  &__text {
    font-size: 15px;
    font-weight: 600;
    display: block;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    margin-right: 8px;
    width: 22px !important;
    height: 22px !important;
  }

  &_active {
    background-color: rgba(#2370c9, 0.15);
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(#2370c9, 0.15);
  }
}
