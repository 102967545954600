.feedbacks-list {
  &__button.MuiButtonBase-root {
    justify-content: flex-start;
    width: 100%;
    padding: 12px 0;
    display: flex;
    background: transparent;
    font-weight: 700;
    font-size: 15px;
    color: rgba(#263238, 0.9);
    padding-left: 0;
    border-radius: 0;

    &:hover {
      background: rgba(35, 112, 201, 0.15);
    }
  }

  &__modal {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 500px;
    width: 100%;
    left: auto !important;

    & .app-modal__container {
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
      width: 500px;
      height: 100%;
      padding: 24px;
      background-color: white;
      padding-top: 24px;
      transform: none;
      box-shadow: none;

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }

    &--feedback {
      & .app-modal__container {
        padding: 0;
        padding-top: 24px;
      }
    }
  }

  &__header {
    font-weight: 700;
    font-size: 17px;
    color: #263238;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__list {
    list-style: none;
  }

  &__feedback {
    background-color: transparent;
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    color: #263238;
    opacity: 0.9;
    padding: 17px 0;
    cursor: pointer;
    transition: $default-transition;
    text-align: start;

    @media screen and (max-width: $media-sm) {
      font-size: 13px;
    }

    @media screen and (max-width: $media-xsm) {
      font-size: 12px;
    }
    &:hover {
      background-color: rgba(#2370c9, 0.15);
    }
  }

  &__danger-icon {
    margin-right: 8px;
  }

  &__feedback-info {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    color: #263238;
    opacity: 0.75;
    white-space: nowrap;
    gap: 8px;
  }

  &__feedback-icon {
    &.MuiSvgIcon-root {
      width: 16px;
      height: 16px;
    }
    &--button {
      margin-left: auto;
      margin-right: 0;
    }
  }
}
