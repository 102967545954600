.select-filter.MuiButtonBase-root {
  padding: 3px 8px;
  line-height: normal;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: normal;
  min-width: unset;
  margin-left: 8px;
  max-width: calc(100% - 16px);
  border-radius: 4px;
  background-color: #d9d9d9;
  color: rgba(black, 0.75);
  &:hover {
    background-color: #d9d9d9;
  }
}

.select-filter {
  &__menu.MuiList-root {
    padding: 0;
  }
  &__menu-paper.MuiPaper-root {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 10px 0;
    background: #d9d9d9;
  }

  &__arrow.MuiSvgIcon-root {
    width: 12px;
    height: 12px;
  }
  &_selected.MuiButtonBase-root {
    white-space: nowrap;
    background-color: #263238;
    color: rgba(white, 0.75);
    &:hover {
      background-color: #263238;
    }
  }

  &__value {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 2px;
  }

  &__delete.MuiButtonBase-root {
    padding: 0;
  }

  &__delete-icon.MuiSvgIcon-root {
    width: 12px;
    height: 12px;
    color: rgba(white, 0.75);
  }

  &__menu-item.MuiMenuItem-root {
    color: rgb(85, 92, 96);
    font-family: Nunito;
    padding: 5px 15px 5px 5px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__menu-item {
    &_selected.MuiMenuItem-root {
      color: #263238;
    }
  }

  &__menu-item-check-icon.MuiSvgIcon-root {
    width: 12px;
    margin-right: 4px;
    height: 12px;
    color: transparent;
  }

  &__menu-item-check-icon {
    &_selected.MuiSvgIcon-root {
      color: #263238;
    }
  }
}
