.editable-phones-list {
  &__btn.MuiButtonBase-root {
    position: relative;
    display: block;
    max-height: 117px;
    overflow-y: scroll;
    width: 100%;
    padding: 12px 16px;
    display: flex;
    word-break: break-word;
    align-items: inherit;
    justify-content: flex-start;
    margin-bottom: 16px;
    text-align: start;
    gap: 8px;
    background: rgba(38, 50, 56, 0.03);
    font-weight: 700;
    font-size: 12px;
    color: #263238;

    &:hover {
      color: black;
      background: rgba(38, 50, 56, 0.05);
    }
  }

  &__btn_no-phone.MuiButtonBase-root {
    color: $color-accent;
    &:hover {
      color: $color-accent;
    }
  }
  &__input-wrapper {
    position: relative;
    margin-bottom: 8px;
  }

  &__edit-buttons {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
    margin-top: 8px;
  }

  &__edit-btn {
    border-radius: 6px;
    opacity: 0.5;
    box-shadow: 0px 0 4px 2px rgba(0, 0, 0, 0.3);
  }

  &__input {
    & .input__wrapper {
      & .MuiInputBase-root {
        max-height: 45px;
        font-size: 14px;
        & .MuiInputBase-input {
          height: 28px;
        }
      }
    }
  }

  &__icon {
    margin-top: 2px;

    &_no-phone {
      fill: $color-accent;
    }
  }
}
