.percentage-progress {
  position: relative;
  height: 64px;
  display: inline-block;
  width: 64px;
  &__progress.MuiCircularProgress-root {
    position: absolute;
    z-index: 1;
    left: 0;
    color: #2574d0;
  }

  &__percents {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    font-size: 10px;
    color: #000000;
    opacity: 0.5;
  }

  &__background.MuiCircularProgress-root {
    // color: ;
    color: rgba(0, 0, 0, 0.25);
  }
}
