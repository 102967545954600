.changelog-list-item {
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  &__card {
    padding: 4px 18px 12px 18px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 16px;
    }
  }

  &__card > &__card-title {
    color: rgba(#263238, 0.9);
    font-size: 15px;
    font-weight: 700;
    @media screen and (max-width: $media-xsm) {
      margin-bottom: 2px;
      display: block;
    }
  }

  &__header-title {
    font-size: 15px;
    font-weight: 700;
    color: rgba(#263238, 0.9);
    margin-bottom: 6px;
  }

  &__header-subtitle {
    font-size: 10px;
    color: rgba(#263238, 0.75);
    font-weight: 700;
    @include text-truncate();
  }

  &__date,
  &__create-by,
  &__theme,
  &__mark,
  &__field-name {
    font-size: 10px;
    color: rgba(#263238, 0.75);
    margin-right: 8px;
  }

  &__meetup-date {
    margin-bottom: 12px;
    @media screen and (max-width: $media-xsm) {
      margin-bottom: 4px;
      display: block;
    }
  }

  &__meetup-create-by {
    margin-right: 32px;
    margin-bottom: 12px;

    @media screen and (max-width: $media-xsm) {
      display: block;
    }
  }

  &__meetup-result-comment {
    font-size: 15px;
    color: rgba(#263238, 0.75);
    font-weight: 400;
  }

  &__info {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__changes {
    display: grid;
    grid-template-columns: auto 24px 1fr;
    align-items: center;
    column-gap: 12px;
    margin-bottom: 4px;
  }

  &__arrow.MuiSvgIcon-root {
    height: 12px;
    display: flex;
    align-items: center;
  }

  &__value {
    font-size: 14px;

    &_old {
      color: $color-accent;
    }

    &_new {
      color: #2f875d;
    }
  }

  &__changes-title {
    font-size: 12px;
    color: rgba(#263238, 0.75);
    margin-right: 8px;
  }
}
