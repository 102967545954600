.sign-in-form {
  margin: 0 auto;
  width: 350px;
  padding: 32px 38px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: white;
  text-align: center;
  margin-bottom: 24px;

  &__title {
    margin-bottom: 18px;
    font-size: 24px;
  }

  &__logo {
    margin: 0 auto;
    margin-bottom: 32px;
  }

  &__login-input {
    width: 100%;
    margin-bottom: 18px;
    background: $color-substrate;
    border: 0;
    border-radius: 4px;
    & .input__field {
      width: 100%;
      & .MuiOutlinedInput-notchedOutline {
        border-width: 0;
      }
    }
  }

  &__password-input {
    margin-bottom: 22px;
    width: 100%;
    background: $color-substrate;
    border-radius: 4px;
    & .input__field {
      width: 100%;
      & .MuiOutlinedInput-notchedOutline {
        border-width: 0;
      }
    }
  }

  &__sign-in.MuiButtonBase-root {
    color: white;
    background: black;
    width: 100%;
    text-transform: none;
    &:hover {
      background: black;
    }
  }

  &__error {
    color: $color-accent;
    max-width: 350px;
    margin: 0 auto;
    padding: 0 38px;
    font-size: 12px;
    text-align: center;
  }
}
