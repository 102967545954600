.history-report-row.MuiTableCell-root {
  font-weight: 400;
  font-size: 14px;
  color: #263238;
  opacity: 0.9;
  padding: 16px 8px;

  &:first-of-type {
  }
}

.history-report-row {
  &__feedback {
    @include text-truncate(2);
  }

  &__comment {
    @include text-truncate(2);
  }

  &__lpu,
  &__specialty {
    font-size: 14px;
    white-space: normal;
    width: fit-content;
    @include text-truncate(1);
    word-break: break-all;
  }

  &__type {
    fill: #2f875d;
    padding-left: 8px;
    width: 32px;
    height: 20px;

    &_failed {
      fill: #263238;
      opacity: 0.6;
    }

    &_envelope {
      height: 14px;
    }
  }

  &__executor {
    display: flex;
    justify-content: center;
  }
}
