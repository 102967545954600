.task-detail-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    @media screen and (max-width: $media-sm) {
      margin-bottom: 4px;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 17px;
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &__header-controls {
    margin-left: auto;
    display: flex;
    gap: 16px;
  }

  &__list {
    flex: 1 0;

    @media screen and (max-width: $media-xsm) {
      max-height: calc(100vh - 300px);
      overflow-y: scroll;
      margin-bottom: 12px;
      padding-right: 16px;
    }
  }

  &__note-btn.MuiButtonBase-root {
    margin-bottom: 24px;
    position: relative;
    min-height: 48px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    line-height: unset;
    font-size: 15px;
    background-color: rgba(#2f875d, 0.1);
    text-align: start;
    justify-content: flex-start;

    &:hover {
      background-color: darken(#f3f3f3, 2);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -12px;
      opacity: 0.15;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: #263238;
    }
  }

  &__note-input {
  }

  &__input-wrapper {
    margin-bottom: 24px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -24px;
      opacity: 0.15;
      height: 1px;
      width: 100%;
      background-color: #263238;
    }
  }

  &__edit-controls {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  &__edit-btn {
    border-radius: 6px;
    opacity: 0.5;
    box-shadow: 0px 0 4px 2px rgba(0, 0, 0, 0.3);
  }

  &__pagination.MuiPagination-root {
    & .MuiPagination-ul {
      justify-content: flex-end;
      flex-wrap: nowrap;
    }
  }

  &__more-btn {
    background: $color-substrate;
  }

  &__filters-btn {
    background: $color-substrate;
  }

  &__filter-img {
    width: 20px;
    height: 20px;
  }

  &__alphabet {
    font-weight: 800;
    font-size: 11px;
    color: #263238;
    opacity: 0.9;
  }

  &__filter-icon {
    margin-right: 8px;
    border-radius: 50%;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-substrate;
  }

  &__alphabet-filter {
    font-weight: 600;
    font-size: 6px;
    line-height: 8px;
    color: #263238;
    opacity: 0.9;
  }

  &__status.MuiChip-root {
    height: 24px;
    @media screen and (max-width: $media-sm) {
      display: none;
    }
  }

  &__status-mobile.MuiChip-root {
    display: none;
    height: 24px;
    margin-bottom: 16px;
    @media screen and (max-width: $media-sm) {
      display: flex;
      max-width: fit-content;
    }
  }

  &__note-input {
    & .input__wrapper {
      & .MuiFormControl-root {
        & .MuiInputBase-root {
          min-height: 48px;
          & .MuiInputBase-input.MuiOutlinedInput-input {
            line-height: unset;
            min-height: unset;
          }
        }
      }
    }
  }

  &__note-label {
    position: absolute;
    top: -8px;
    left: 13px;
    padding: 0 4px;
    font-size: 11px;
    color: black;
    background-color: white;
    letter-spacing: unset;
  }

  &__search-bar {
    background-color: rgba(#d9d9d9, 0.5);
    margin-bottom: 12px;

    &_searching {
      color: white;
      background-color: black;

      .search-bar {
        &__round-button-icon.MuiSvgIcon-root {
          color: white;
        }

        &__search-icon {
          fill: white;
        }

        &__input {
          &::placeholder {
            color: rgba(white, 0.8);
          }
        }
      }
    }
  }
}
