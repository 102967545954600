.users-page {
  display: flex;
  width: 100%;
  min-height: 100vh;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 32px;
  }

  &__sidebar {
    @media screen and (max-width: $media-sm) {
      display: none;
    }
  }

  &__app-modal {
    .app-modal__container {
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
      width: 500px;
      height: 100%;
      background-color: white;
      padding: 24px;
      transform: none;

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }
}
