.completed-meetups-page {
  display: flex;
  width: 100%;
  min-height: 100vh;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 32px;

    @media screen and (max-width: $media-xsm) {
      padding: 0 12px;
    }
  }

  &__sidebar {
    @media screen and (max-width: $media-sm) {
      display: none;
    }
  }

  &__title-wrapper {
    display: flex;
  }

  &__title {
    font-size: 17px;
    font-weight: 500;
    @include text-truncate(1);
    word-break: break-all;
  }

  &__burger-menu {
    margin-right: 12px;
    display: none;

    @media screen and (max-width: $media-sm) {
      display: block;
    }
  }

  &__title-container {
    position: relative;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -32px;
      background-color: rgba(black, 0.15);
      width: calc(100% + 64px);
      height: 1px;
    }
  }

  &__title {
    font-size: 17px;
    font-weight: 500;
  }
  &__controls {
    display: flex;
    gap: 16px;
  }

  &__modal.MuiModal-root {
    z-index: 1303;
    overflow: hidden;
    .app-modal__container {
      overflow: scroll;

      padding: 0;
      max-width: 375px;
      height: 610px;
      width: 100%;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      @media screen and (max-width: $media-sm) {
        max-width: 100%;
        height: 100%;
        top: 0;
      }
    }
  }

  &__side-menu {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 500px;
    width: 100%;
    left: auto !important;

    .app-modal__container {
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
      width: 500px;
      height: 100%;
      background-color: white;
      padding: 24px;
      transform: none;
      box-shadow: 15px 0 40px -5px rgba(0, 0, 0, 0.3);

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

  &__alphabet-btn {
    font-weight: 800;
    font-size: 11px;
    color: #263238;
    opacity: 0.9;
  }

  &__filter-icon {
    width: 22px;
    height: 22px;
  }

  &__filter-btn-icon {
    margin-right: 8px;
    border-radius: 50%;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-substrate;
  }

  &__alphabet-filter {
    font-weight: 600;
    font-size: 11px;
    line-height: 8px;
    color: #263238;
    opacity: 0.9;
  }

  &__alphabet-menu-filter {
    font-weight: 600;
    font-size: 11px;
    line-height: 8px;
    margin-right: 9px;
    color: #263238;
    opacity: 0.9;
  }

  &__filter-icon-menu {
    width: 16px;
    height: 16px;
    margin-right: 13px;
    margin-left: 3px;
  }

  &__search-btn {
    & .search-button__input {
      color: #263238;
      font-size: 15px;
      font-weight: normal;
    }
  }
}
