.feedback-page {
  display: flex;
  width: 100%;
  min-height: 100vh;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 32px;

    @media screen and (max-width: $media-xsm) {
      padding: 0 12px;
    }
  }

  &__sidebar {
    @media screen and (max-width: $media-sm) {
      display: none;
    }
  }

  &__title {
    display: flex;
    align-items: center;
  }

  &__burger-menu {
    margin-right: 12px;
    display: none;

    @media screen and (max-width: $media-sm) {
      display: block;
    }
  }

  &__title-container {
    position: relative;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -32px;
      background-color: rgba(black, 0.15);
      width: calc(100% + 64px);
      height: 1px;
    }
  }

  &__title {
    font-size: 17px;
    font-weight: 500;
  }

  &__header-controls {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__create-task.MuiButtonBase-root {
    background-color: transparent;
    color: #2574d0;
    &:hover {
      background-color: transparent;
    }
  }

  &__select-button {
    transition: background-color 0.12s ease-in-out;
    &.selecting {
      background-color: #2574d0;
    }
  }

  &__select-icon {
    width: 16px;
    height: 16px;
    fill: #263238;
    transition: fill 0.12s ease-in-out;

    &.selecting {
      fill: white;
    }
  }

  &__app-modal {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 500px;
    width: 100%;
    left: auto !important;

    .app-modal__container {
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
      width: 500px;
      height: 100%;
      background-color: white;
      padding: 24px;
      transform: none;
      box-shadow: 15px 0 40px -5px rgba(0, 0, 0, 0.3);

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }

    &--feedbacks {
      .app-modal__container {
        padding: 0;
        padding-top: 24px;
      }
    }
  }
}
