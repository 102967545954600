.attach-image {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100px;
  margin-bottom: 13px;
  gap: 8px;
  &__upload-btn {
    cursor: pointer;
    min-width: 100px;
    background-color: rgba(38, 50, 56, 0.03);
    border-radius: 8px;
    border: none;
  }

  &__btn-icon-wrapper {
    padding: 10px 23px 8px 23px;
    border: 1.5px solid #000000;
    width: fit-content;
    margin: 0 auto;
    border-radius: 50px;
    opacity: 25%;
  }

  &__btn-icon {
  }

  &__image-wrapper {
    min-width: 100px;
    max-width: 100px;
    position: relative;
  }

  &__remove-btn.MuiButtonBase-root {
    background-color: white;
    top: 4px;
    right: 4px;
    position: absolute;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}
