.user-item {
  padding: 12px 0;
  width: fit-content;

  &__wrapper {
    padding: 4px;
    border-radius: 4px;
    cursor: pointer;
    width: fit-content;
    &:hover {
      background-color: rgba(#2370c9, 0.15);
    }
  }
  &__name {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 15px;
  }

  &__role {
    font-size: 12px;
  }
}
