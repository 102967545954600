.notice {
  position: relative;
  padding: 14px 16px;
  background-color: #f3f3f3;
  border-radius: 6px;

  &__text {
    font-size: 15px;
    color: #263238;
  }

  &__chip {
    position: absolute;
    top: -9px;
    left: 12px;
    background-color: white;
    font-size: 11px;
    font-weight: 700;
    color: rgba(#263238, 0.3);
    border-radius: 6px;
    padding: 2px 4px;
  }
}