.meetup-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  position: relative;
  @media screen and (max-width: $media-sm) {
    align-items: flex-start;
    padding: 8px 0;
    margin-bottom: 4px;
    min-height: 88px;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__envelope-container {
    transition: all 0.2s ease;
    overflow: hidden;
    width: 0;
    min-width: 0;

    &_open {
      width: 40px;
      min-width: 40px;
    }

    &_close {
      width: 0;
    }
  }

  &__envelope-given-icon {
    display: inline;
    margin-left: 6px;
  }
  &__envelope-given-icon.not-given {
    path {
      fill: #263238;
      opacity: 0.3;
    }
  }

  &__fullname {
    font-weight: 700;
    font-size: 15px;
    color: #263238;
    opacity: 0.9;
    margin-bottom: 8px;
    @include text-truncate(1);
    word-break: break-all;
    @media screen and (max-width: $media-sm) {
      font-size: 17px;
      margin-bottom: 6px;
    }
  }

  &__subinfo {
    display: flex;
    align-items: center;
    width: 100%;
    @media screen and (max-width: $media-sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__doctor-info-wrapper {
    @include text-truncate(1);
  }

  &__doctor-info {
    font-weight: 700;
    font-size: 10px;
    color: #263238;
    opacity: 0.75;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: nowrap;
    @media screen and (max-width: $media-sm) {
      color: #263238;
      font-size: 15px;
      font-weight: normal;
      white-space: normal;
    }
  }

  &__note-wrapper {
    display: flex;
    align-items: center;
    margin-left: 24px;
    @media screen and (max-width: $media-sm) {
      margin-left: 0;
      margin-top: 8px;
      border-radius: 6px;
      padding: 8px 10px;
      // opacity: 0.10000000149011612;
      background-color: rgba(#2370c9, 0.1);
    }
  }

  &__note {
    font-weight: 700;
    font-size: 10px;
    color: #263238;
    opacity: 0.75;
    @include text-truncate(1);
    word-break: break-all;
    @media screen and (max-width: $media-sm) {
      color: #263238;
      font-size: 15px;
      font-weight: 500;
    }
  }

  &__note-icon {
    width: 12px;
    height: 12px;
    margin-right: 2px;
    min-width: 10px;
  }

  &__labels {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &:hover {
    background-color: rgba(35, 112, 201, 0.15);
  }
  &.selected {
    background-color: rgba(35, 112, 201, 0.15);
  }

  &__subinfo-span {
    &:first-of-type {
      max-width: 200px;
    }
  }

  &__doctor-info-wrapper {
    @media screen and (max-width: $media-xsm) {
      max-width: 285px;
    }
  }
}
