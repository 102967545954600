.rounded-label {
  width: 18px;
  height: 18px;
  background: black;
  color: white;
  border-radius: 50%;
  font-size: 8px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.408px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}
