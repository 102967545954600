.add-user-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__input {
    margin-bottom: 16px;
    border-radius: 6px;
  }
  &__fields {
    flex: 1 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  &__controls {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    height: 38px;
    width: 100%;
  }

  &__close-btn {
    display: none;
    @media screen and (max-width: $media-sm) {
      display: flex;
    }
  }

  &__cancel.MuiButtonBase-root {
    color: black;
    background: $color-substrate;
    width: 140px;
    &:hover {
      background: rgba(0, 0, 0, 0.06);
    }
  }

  &__save.MuiButtonBase-root {
    width: 140px;
    color: white;
    background: #263238;
    width: 140px;
    &:hover {
      background: black;
    }
  }
  &__checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: end;
  }

  &__check-box {
    justify-content: end;
  }

  &__error-alert {
    margin-bottom: 24px;
    justify-content: center;
  }
}
