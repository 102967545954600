.close-envelops-form {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 56px;

  &__header,
  &__envelop-status-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__doctors {
    margin-bottom: 16px;
    font-size: 14px;
  }

  &__send.MuiButtonBase-root.MuiButton-root {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  &__close.MuiButtonBase-root.MuiButton-root {
    color: $color-action;
    background-color: transparent;
  }

  &__close_not-take {
    background-color: #263238 !important;
    opacity: 0.75 !important;
  }

  &__envelop-status {
    font-size: 16px;
    font-weight: 700;
  }

  &__title {
    font-size: 17px;
  }

  &__comment.MuiTextField-root {
    flex: 1;

    & .MuiInputBase-root {
      padding: 0;
      height: 100%;

      & .MuiInputBase-input {
        background-color: transparent;
        border-radius: 0;
        height: 100% !important;
      }
    }
  }

  &__select {
    width: 100%;
  }
}
