.meetup-details {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: scroll;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__doctor-name {
    font-weight: 700;
    font-size: 17px;
    color: #263238;
    margin-bottom: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__doctor-info {
    flex: 1 0;
    margin-bottom: 16px;
  }

  &__doctor-subinfo {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    // justify-content: space-between;
  }

  &__facility {
    font-weight: 700;
    font-size: 15px;
    color: #263238;
    opacity: 0.75;
    width: fit-content;
  }

  &__active-tasks {
    margin-top: 24px;
    flex: 1 0;
  }

  &__tasks-header {
    position: relative;
    color: #263238;
    opacity: 0.75;
    margin-bottom: 16px;
    font-size: 12px;
    font-weight: 700;
    &::after {
      display: block;
      position: absolute;
      bottom: -16px;
      content: '';
      height: 1px;
      background: #263238;
      width: 100%;
      opacity: 0.15;
    }
  }

  &__skeleton-name.MuiSkeleton-root {
    margin-bottom: 7px;
    max-width: 260px;
    width: 100%;
  }

  &__skeleton-facility.MuiSkeleton-root {
    width: 170px;
    margin-bottom: 4.5px;
  }

  &__skeleton-phone.MuiSkeleton-root {
    height: 15px;
    margin: 3px 0;
  }

  &__mobile-modal {
    z-index: 1303;
    overflow: hidden;
    .app-modal__container {
      overflow: scroll;

      padding: 0;
      max-width: 375px;
      height: 610px;
      width: 100%;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      @media screen and (max-width: $media-sm) {
        max-width: 100%;
        height: 100%;
        top: 0;
      }
    }
  }

  &__note {
    & .MuiButtonBase-root {
      @media screen and (max-width: $media-sm) {
        color: #263238;
        font-size: 15px;
        font-weight: 600;
        padding: 8px 16px;
      }
    }

    & .editable-note__icon {
      @media screen and (max-width: $media-sm) {
        top: 5px;
      }
    }
  }

  & .phone-button-mobile {
    &__phone-btn.MuiButtonBase-root {
      @media screen and (max-width: $media-sm) {
        color: #263238;
        font-size: 15px;
        font-weight: 600;
        padding: 8px 16px;
        height: unset;
      }
    }
  }

  &__active-list {
    &_feedbacks {
      margin-bottom: 12px;
    }
  }
}
