.completed-meetup-card {
  &__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  &__meetup-card {
    padding: 18px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 16px;
    }
    & .completed-meetup-card__meetup-info:last-child {
      margin-bottom: 0;
    }
  }

  &__meetup-card > &__meetup-card-title {
    color: rgba(#263238, 0.9);
    font-size: 15px;
    font-weight: 700;
    @media screen and (max-width: $media-xsm) {
      margin-bottom: 2px;
      display: block;
    }
  }

  &__header-title {
    font-size: 15px;
    font-weight: 700;
    color: rgba(#263238, 0.9);
    margin-bottom: 6px;
  }

  &__header-subtitle {
    font-size: 10px;
    color: rgba(#263238, 0.75);
    font-weight: 700;
    @include text-truncate();
  }

  &__meetup-date,
  &__meetup-create-by,
  &__meetup-theme,
  &__meetup-mark {
    font-size: 10px;
    color: rgba(#263238, 0.75);
    margin-right: 8px;
  }

  &__meetup-date {
    @media screen and (max-width: $media-xsm) {
      margin-bottom: 4px;
      display: block;
    }
  }

  &__meetup-create-by {
    margin-right: 32px;
    @media screen and (max-width: $media-xsm) {
      display: block;
    }
  }

  &__meetup-result-comment {
    font-size: 15px;
    color: rgba(#263238, 0.75);
    font-weight: 400;
  }

  &__meetup-info {
    margin-bottom: 8px;
  }
}
