.meetup-history-item {
  position: relative;

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
    background-color: black;
    opacity: 0.1;
  }

  &:last-child {
    &::after {
      height: 0px;
    }
  }
  &_have-doctor {
    padding-bottom: 12px;
    &::after {
      bottom: -4px;
    }
  }
  &__doctor {
    padding-bottom: 8px;
    @media screen and (max-width: $media-xsm) {
      width: calc(100vw - 24px);
    }
  }

  &__doctor-name {
    margin-bottom: 4px;
    color: #263238;
    font-size: 15px;
    font-weight: 700;
    opacity: 0.9;
  }

  &__doctor-info {
    @include text-truncate(1);
  }

  &__header {
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
  }

  &__icon-wrapper {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #2f875d;
    display: flex;
    align-items: center;
    justify-content: center;
    &_failed {
      background: rgb(233, 235, 235);
    }
  }

  &__icon {
    fill: white;
    &_failed {
      fill: black;
    }
  }

  &__type-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__type {
    color: #263238;
    font-size: 15px;
    font-weight: 700;
    opacity: 0.9;
  }

  &__rounded-label {
    width: 22px;
    height: 22px;
    font-size: 10px;
    display: none;

    @media screen and (max-width: $media-sm) {
      display: flex;
    }

    &_short {
      display: flex;
    }
  }

  &__executor {
    display: flex;
    align-items: center;
    gap: 4px;
    color: rgba(#263238, 0.75);
    font-family: Nunito;
    font-size: 10px;
    font-weight: 700;
  }

  &__executor-name {
    display: block;

    @media screen and (max-width: $media-sm) {
      display: none;
    }
  }

  &__meetup {
    padding: 8px 0;

    &_editable {
      cursor: pointer;

      &:hover {
        background-color: rgba(35, 112, 201, 0.15);
      }
    }
  }

  &__info {
    position: relative;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.03);
    padding: 16px 24px;

    &_feedback {
      background-color: rgba(#ec6d62, 0.1);
    }
    &_comment {
      background-color: rgba(#2f875d, 0.1);
    }
  }

  &__label {
    position: absolute;
    left: 24px;
    top: -9px;
    padding: 1px 4px;
    color: rgba(#263238, 0.75);
    font-size: 11px;
    font-weight: 700;
    background-color: white;
    border-radius: 6px;
  }
  &__score {
    color: rgba(#263238, 0.75);
    font-size: 10px;
    font-weight: 700;
  }

  &__content {
    color: rgba(#263238, 0.75);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 4px;
  }

  &__next-icon {
    opacity: 0.75;
  }
}
