.no-data-placeholder {
  border-radius: 8px;
  border: 1px solid rgba(123, 126, 128, 0.3);
  background: rgba(38, 50, 56, 0.03);
  display: flex;
  justify-content: center;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
    flex-direction: column;
  }

  &__icon-wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: rgba(#2574d0, 0.1);
  }

  &__icon {
    width: 20px;
    height: 18px;
    fill: #2574d0;
  }

  &__label {
    font-size: 14px;
    font-weight: 600;
    opacity: 0.9;
  }
}
