.auto-complete-filter.MuiButtonBase-root {
  padding: 3px 8px;
  line-height: normal;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: normal;
  min-width: unset;
  margin-left: 8px;
  max-width: calc(100% - 16px);
  border-radius: 4px;
  background-color: #d9d9d9;
  color: rgba(black, 0.75);
  &:hover {
    background-color: #d9d9d9;
  }
}

.auto-complete-filter {
  &__search-list-wrapper {
    width: 300px;
    max-height: 250px;
    background-color: #d9d9d9;
  }
  &__menu.MuiList-root {
    padding: 0;
  }
  &__menu-paper.MuiPaper-root {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  &__arrow.MuiSvgIcon-root {
    width: 12px;
    height: 12px;
  }
  &_selected.MuiButtonBase-root {
    white-space: nowrap;
    background-color: #263238;
    color: rgba(white, 0.75);
    &:hover {
      background-color: #263238;
    }
  }

  &__search-list {
    position: relative !important;
    border-radius: 4px !important;
    background-color: #d9d9d9 !important;
  }

  &__value {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 2px;
  }

  &__delete.MuiButtonBase-root {
    padding: 0;
  }

  &__delete-icon.MuiSvgIcon-root {
    width: 12px;
    height: 12px;
    color: rgba(white, 0.75);
  }
}
