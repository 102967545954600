.meetups-report {
  flex: 1;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
  }

  &__burger-menu {
    margin-right: 12px;
    display: none;

    @media screen and (max-width: $media-sm) {
      display: block;
    }
  }

  &__header-container {
    position: relative;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -32px;
      background-color: rgba(black, 0.15);
      width: calc(100% + 64px);
      height: 1px;
    }
  }

  &__title {
    font-size: 17px;
    font-weight: 500;
  }

  &__dynamic-container {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.75);
  }

  &__period-container {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.75);
  }

  &__period.MuiButtonBase-root {
    background: transparent;
    padding-right: 0px;
    padding-left: 6px;
    margin-left: 8px;
    color: black;
    &:hover {
      background-color: $color-substrate;
    }
  }
  &__table.MuiTableContainer-root {
    margin-top: 16px;
    white-space: nowrap;
    width: calc(100vw - 285px - 64px);
    @media screen and (max-width: $media-sm) {
      width: calc(100vw - 64px);
    }
    @media screen and (max-width: $media-xsm) {
      width: calc(100vw - 24px);
    }
  }

  &__filters {
    display: flex;
    gap: 18px;
  }

  &__fullname {
    font-weight: 700;
    font-size: 15px;
    color: #263238;
    opacity: 0.9;
  }

  &__dynamic {
    font-weight: 500;
    font-size: 15px;
    color: #2f875d;
    opacity: 0.95;
    position: relative;
    margin: 0;
    &.negative {
      color: #ff4c06;
    }
  }

  &__dynamic-indicator.MuiSvgIcon-root {
    position: absolute;
    left: -20px;
    top: -2px;
    &.positive {
      transform: rotate(180deg);
    }
  }

  &__summary {
    font-weight: 500;
    font-size: 15px;
    color: #263238;
    opacity: 0.95;
  }

  &__table-heading.MuiTableCell-root {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #263238;
    opacity: 0.5;
  }

  &__no-data {
    flex: 1;
    margin-top: 16px;
    margin-bottom: 48px;
  }
}
