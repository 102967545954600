.changelog-loader {
  margin-bottom: 40px;
  &__title.MuiSkeleton-root {
    height: 15px;
    width: 320px;
    margin-bottom: 11.5px;
  }
  &__subtitle.MuiSkeleton-root {
    height: 10px;
    width: 170px;
    margin-bottom: 3.5px;
  }

  &__info {
    padding-left: 17px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
  &__type.MuiSkeleton-root {
    height: 15px;
    width: 100px;
    margin-bottom: 11.5px;
  }
  &__meetup-info.MuiSkeleton-root {
    height: 10px;
    width: 170px;
    margin-bottom: 11.5px;
  }
}
