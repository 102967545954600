.sign-in-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__content {
    width: 100%;
    position: relative;
  }

  &__container {
    flex: 1;
    display: flex;
    height: 100%;
    padding-top: 8vh;
  }
}
