.image-slider {
  max-width: 90%;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &__image-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
  }

  &__image {
    width: 100%;
    object-fit: contain;
    height: auto;
  }

  &__close.MuiButtonBase-root {
    position: absolute;
    top: 32px;
    right: 32px;
    color: white;
    z-index: 10;
  }

  &__icon.MuiSvgIcon-root {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));
  }

  &__control {
    width: 20vw;
    height: 100%;
    color: white;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}
