.editable-note {
  &__input-wrapper {
    position: relative;
  }

  &__input {
    & .input__wrapper {
      & .MuiInputBase-root {
        min-height: 45px;
        font-size: 14px;
        & .MuiInputBase-input.MuiOutlinedInput-input {
          min-height: unset;
        }
      }
    }
  }

  &__edit-buttons {
    // position: absolute;
    z-index: 2;
    right: 0;
    bottom: -38px;
    display: flex;
    margin-top: 8px;
    justify-content: flex-end;
    gap: 12px;
  }

  &__edit-btn {
    border-radius: 6px;
    opacity: 0.5;
    box-shadow: 0px 0 4px 2px rgba(0, 0, 0, 0.3);
  }

  &__note.MuiButtonBase-root {
    position: relative;
    display: block;
    max-height: 117px;
    overflow-y: scroll;
    width: 100%;
    padding: 12px 16px;
    display: flex;
    word-break: break-word;
    align-items: inherit;
    justify-content: flex-start;
    text-align: start;
    gap: 8px;
    background: rgba(38, 50, 56, 0.03);
    font-weight: 700;
    font-size: 12px;
    color: #263238;
    &:hover {
      background: rgba(38, 50, 56, 0.05);
    }
  }

  &__icon {
    align-self: flex-start;
    min-width: 16px;
    position: sticky;
    left: 0;
    top: 3px;
  }
}
