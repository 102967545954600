.edit-user-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__input {
    margin-bottom: 16px;
    width: 100%;
    // background: $color-substrate;
    border-radius: 6px;
    & .input__field {
      width: 100%;

      & .MuiInputBase-input.Mui-disabled {
        -webkit-text-fill-color: black;
      }
    }
  }
  &__fields {
    flex: 1 0;
  }
  &__check-box {
    justify-content: end;
  }

  &__header {
    display: flex;
    margin-bottom: 24px;
    align-items: center;
    @media screen and (max-width: $media-xsm) {
      margin-bottom: 16px;
    }
  }
  &__title {
    margin-right: 16px;
  }

  &__header-controls {
    margin-left: auto;
    display: flex;
    gap: 16px;
  }

  &__cancel.MuiButtonBase-root {
    color: black;
    background: $color-substrate;
    width: 140px;
    &:hover {
      background: rgba(0, 0, 0, 0.06);
    }
  }

  &__save.MuiButtonBase-root {
    width: 140px;
    color: white;
    background: #263238;
    width: 140px;
    &:hover {
      background: black;
    }
  }

  &__chip.MuiChip-root {
    height: 24px;
    @media screen and (max-width: $media-xsm) {
      display: none;
    }
  }

  &__edit-btn {
    background-color: $color-substrate;
  }

  &__mobile-chip.MuiChip-root {
    display: none;
    width: fit-content;
    height: 24px;
    margin-bottom: 16px;
    @media screen and (max-width: $media-xsm) {
      display: flex;
    }
  }

  &__controls {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    margin-top: auto;
  }

  &__loaders {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-end;
  }

  &__error-alert {
    margin-bottom: 24px;
    justify-content: center;
  }

  &__info-controls {
    display: flex;
    justify-content: space-between;
  }

  &__change-password-btn.MuiButtonBase-root {
    // display: inline;
    background-color: transparent;
    color: $color-action;
    &:hover {
      background-color: transparent;
    }
  }

  &__change-password-title {
    font-size: 20px;
    font-weight: 800;
    opacity: 0.75;
    margin-bottom: 32px;
  }

  &__change-password-modal {
    & .app-modal__container {
      border-radius: 6px;
      height: unset;
      max-width: 500px;
      width: 100%;
    }
  }

  &__password-confirm.MuiButtonBase-root {
    display: block;
    margin: 24px auto 8px;
    width: 140px;
    color: white;
    background: #263238;
    width: 140px;
    &:hover {
      background: black;
    }
  }

  &__change-password-alert {
    justify-content: center;
  }
}
