.upload-field {
  height: 168px;
  background-color: rgba(38, 50, 56, 0.03);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  @media screen and (max-width: $media-sm) {
    flex-direction: column;
  }

  &__text-wrapper {
    display: flex;
    align-items: center;
    min-height: 29px;
    margin-bottom: 8px;
  }

  &__instruction {
    font-weight: 400;
    font-size: 12px;
    margin-right: 4px;
  }

  &__icon {
    margin-right: 8px;
  }

  &__icon.MuiSvgIcon-root {
    width: 12px;
    height: 12px;
  }

  &__file-btn.MuiButtonBase-root {
    background: none;
    color: $color-action;
    font-weight: 600;
    font-size: 12px;
    padding: 4px;
    &:hover {
      background: none;
      color: $color-action;
    }
  }

  &__file-btn.MuiButtonBase-root.Mui-disabled {
    background-color: transparent;
    &:disabled {
      color: lighten($color-action, 30) !important;
    }
  }

  &__watch {
    animation: spin 2s linear 0s infinite;
  }

  &__date-picker {
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &__error {
    font-size: 12px;
    white-space: nowrap;
    gap: 4px;
  }
}
