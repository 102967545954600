.add-doctor-form {
  position: relative;
  height: 100%;
  // overflow-y: scroll;
  display: flex;
  flex-direction: column;

  &__heading {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close-modal {
    @media screen and (max-width: 450px) {
      display: block;
    }
  }

  &__form-input {
    margin-bottom: 16px;
    flex: 1;

    & .MuiAutocomplete-clearIndicator {
      @media screen and (max-width: $media-xsm) {
        visibility: visible !important;
      }
    }
  }

  &__buttons {
    // position: absolute;
    // bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__cancel.MuiButtonBase-root.MuiButton-root {
    margin-right: 16px;
    width: 141px;
    background-color: #efefef;
    color: #263238;
  }

  &__save.MuiButtonBase-root.MuiButton-root {
    width: 141px;
    background-color: #263238;
  }

  &__mobile-check-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__phone-container,
  &__lpu-container {
    display: flex;
  }

  &__add-phone {
    margin-left: 8px;
    transform: translateY(6px);
  }

  &__mobile-search {
    position: fixed !important;
    top: 0;
    left: 0;
    padding: 24px 24px 64px 24px !important;
    height: 100%;
    max-height: unset !important;

    & .search-bar {
      &__input {
        font-size: 18px;
      }
    }
    & .auto-complete-search-list {
      &__item {
        font-size: 18px;
        padding: 12px 12px;
      }
    }
  }

  &__mobile-phone-container {
    &.MuiButtonBase-root {
      background-color: #f3f3f3;
      width: 100%;
      margin-bottom: 16px;
      height: 48px;
      text-align: left;
      justify-content: start;
      color: rgba(154, 154, 154, 0.87);
      letter-spacing: normal;
      font-weight: 400;
      font-size: 16px;
      border-radius: 6px;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
      &:hover {
        background-color: #f3f3f3;
      }
    }
    &_have-value.MuiButtonBase-root {
      color: currentColor;
    }
  }

  &__mobile-contacts {
    position: fixed !important;
    background: white;
    width: 100%;
    height: 100vh;
    z-index: 1500;
    top: 0;
    left: 0;
  }
}
