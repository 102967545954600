.search-button {
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  max-width: 250px;
  width: 100%;
  transition: width 0.3s ease;

  &__round-button {
    position: absolute;
    top: 50%;
    right: 0;
    width: 32px;
    height: 32px;
    min-width: 18px;
    transform: translateY(-50%);
    // background-color: white;
  }

  &__round-button-icon {
    width: 18px !important;
    height: 18px !important;
  }

  &__input {
    height: 32px;
    border: none;
    outline: none;
    padding-right: 36px;
    padding-left: 12px;
    width: 100%;
    border-radius: 50px;
  }
}
