.upload-data-page {
  display: flex;
  width: 100%;
  min-height: 100vh;
  &__burger-menu {
    margin-right: 12px;
    display: none;

    @media screen and (max-width: $media-sm) {
      display: block;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 32px;

    @media screen and (max-width: $media-xsm) {
      padding: 0 12px;
    }
  }

  &__sidebar {
    @media screen and (max-width: $media-sm) {
      display: none;
    }
  }

  &__title-container {
    position: relative;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -32px;
      background-color: rgba(black, 0.15);
      width: calc(100% + 64px);
      height: 1px;
    }
    // @media screen and (max-width: $media-sm) {
    //   justify-content: flex-start;
    // }

    @media screen and (max-width: 860px) {
      height: 116px;
      gap: 12px;
    }
  }

  &__title {
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
    width: 100%;

    @media screen and (max-width: $media-xsm) {
      max-width: 30%;
      font-size: 14px;
    }
  }

  &__upload-controls {
    display: flex;
    gap: 16px;

    @media screen and (max-width: 860px) {
      gap: 2px;
      flex-direction: column;
    }
  }

  &__upload-control.MuiButtonBase-root {
    background: none;
    color: $color-action;
    white-space: nowrap;
    padding-left: 4px;
    padding-right: 4px;

    &:hover {
      background: none;
    }

    @media screen and (max-width: $media-sm) {
      font-size: 14px;
    }
  }

  &__create-task-control {
    position: relative;
    white-space: nowrap;
    padding-left: 4px;
    padding-right: 4px;
    max-width: 285px;
    width: 100%;

    @media screen and (max-width: $media-sm) {
      font-size: 14px;
    }
  }

  &__tab-wrapper {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    // margin-bottom: 32px;
  }

  &__menu.MuiList-root {
    padding: 0;
  }

  &__menu-content {
    padding: 16px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 300px;
  }

  &__cancel-text {
    line-height: 20px;
    font-weight: 500;
    font-size: 15px;
    color: #263238;
    padding-bottom: 16px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      opacity: 0.15;
      height: 1px;
      width: 100%;
      right: 0;
      background-color: #263238;
    }
  }

  &__cancel.MuiButtonBase-root {
    display: block;
    margin-left: auto;
    font-weight: 700;
    font-size: 15px;
    color: #ff4c06;
    background-color: transparent;
    margin-top: 8px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  &__upload-name-btn.MuiButtonBase-root {
    background-color: transparent;
    color: black;
    font-size: 17px;
    font-weight: 500;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  &__filename-input-wrapper {
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
  }
  &__edit-control {
    border-radius: 6px;
    opacity: 0.6;
  }

  &__filename-input {
    max-width: 500px;
    width: 100%;

    & .input__wrapper {
      & .MuiFormControl-root {
        width: 100%;
        & .MuiInputBase-root {
          width: 100%;
          & .MuiInputBase-input {
            width: 100%;
            background: white;
            font-size: 17px;
            font-weight: 500;
          }
        }
      }
    }
  }

  &__confirm-menu {
    & .delete-confirm-menu__cancel {
      color: $color-action;
      font-weight: 600;
      width: 100%;
    }
  }

  &__spinner.MuiCircularProgress-root {
    position: absolute;
    color: black;
  }
}
