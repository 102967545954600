.meetup-task {
  display: flex;
  position: relative;
  padding: 16px 0;
  cursor: pointer;
  align-items: start;

  &__info {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &__info-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__variant {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 700;
    font-size: 15px;
    color: #263238;
    opacity: 0.9;
  }

  &__note {
    margin-bottom: 4px;
    font-weight: 400;
    font-size: 15px;
    color: #263238;
    opacity: 0.75;
    border-radius: 6px;
    background: rgba(#2370c9, 0.1);
    padding: 8px;
    width: 100%;
    margin: 0 auto;
    margin-top: 8px;
  }

  &__date-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  &__date {
    font-weight: 700;
    font-size: 10px;
    color: #263238;
    opacity: 0.75;
  }

  &__completed-icon {
    margin-top: 4px;
    margin-right: 8px;
    width: 12px;
    height: 12px;
    fill: black;
    opacity: 0.5;
  }
  &::after {
    display: block;
    position: absolute;
    bottom: 0px;
    content: '';
    height: 1px;
    background: #263238;
    width: 100%;
    opacity: 0.15;
  }
  &:last-child {
    &::after {
      height: 0;
    }
  }
  &:hover {
    background-color: rgba(35, 112, 201, 0.15);
  }

  &__icon-wrapper {
    background: $color-accent;
    min-width: 20px;
    height: 20px;
    display: flex;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &.completed {
      background: #2f875d;
    }
    &.failed {
      background: rgb(233, 235, 235);
    }
  }

  &__icon {
    fill: white;
    &.failed {
      fill: black;
    }
  }

  &__next-icon {
    margin-top: 1px;
    min-width: 16px;
    margin-left: 8px;
  }

  &__finished-initials {
    background-color: black;
    color: white;
    border-radius: 50%;
    min-width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
  }
}

.meetup-task.completed {
  opacity: 0.7;
}
