.burger-menu {
  &__icon-container {
    height: 24px;
    min-width: 24px;
    max-width: 24px;
    cursor: pointer;
  }

  &__drawer.MuiPaper-root.MuiDrawer-paper.drawer {
    width: 320px !important;


    @media screen and (max-width: $media-sm) {
      width: 70% !important;
    }
  }

  &__sidebar {
    border-right: none !important;
    padding-right: 24px !important;
  }
}