.phone-button-mobile {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 16px 0;

  &__phone-btn.MuiButtonBase-root {
    flex: 1;
    position: relative;
    display: block;
    padding: 12px 16px;
    display: flex;
    word-break: break-all;
    align-items: inherit;
    justify-content: flex-start;
    text-align: start;
    gap: 8px;
    background: rgba(38, 50, 56, 0.03);
    font-weight: 700;
    font-size: 12px;
    height: 45px;
    color: #263238;

    &:hover {
      color: black;
      background: rgba(38, 50, 56, 0.05);
    }
  }

  &__phones {
    @include text-truncate(1);
    &_no-phones {
      color: $color-accent;
    }
  }

  &__edit-phone-btn {
    border-radius: 6px;
    background: rgba(38, 50, 56, 0.03);
    min-width: 45px;
    height: 45px;
    @media screen and (max-width: $media-sm) {
      min-width: unset;
      width: 42.25px;
      height: 42.25px;
    }
  }

  &__phone-icon {
    min-width: 16px;

    &_no-phones {
      fill: $color-accent;
    }
  }
}
