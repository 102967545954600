.meetup-card {
  padding: 12px;
  border-bottom: 1px solid #ebebeb;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px;
    align-items: center;
  }

  &__comment-header,
  &__comment-footer,
  &__card-info {
    display: flex;
    justify-content: space-between;
  }

  &__comment-header {
    margin-bottom: 8px;
  }

  &__title {
    font-size: 15px;
    font-weight: 600;
    color: #a5a5a5;
    display: flex;
    align-items: center;
  }

  &__rating,
  &__comment-created-date,
  &__comment-updated-date,
  &__comment-created-by,
  &__comment-updated-by,
  &__meetup-created,
  &__meetup-finished {
    font-size: 10px;
    color: #a5a5a5;
  }

  &__comment-created-date {
    margin-right: 8px;
  }

  &__comment {
    color: #a5a5a5;
    font-size: 15px;
    margin-bottom: 8px;
    display: inline-block;
  }

  &__feedback-comment {
    position: relative;
    margin-bottom: 16px;
    padding-left: 8px;

    &:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 2px;
      border-radius: 4px;
      background-color: #ededed;
      top: 0;
      left: 0;
    }
  }

  &__card-info {
    margin-bottom: 8px;
  }

  &__feedback {
    margin-bottom: 8px;
  }

  &__icon-wrapper {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
    &_finished {
      background-color: #2f875d;
    }
  }

  &__icon {
    width: 12px;
    height: 12px;
  }

  &__next {
    opacity: 0.5;
  }

  &_own {
    cursor: pointer;
    &:hover {
      background-color: rgba(35, 112, 201, 0.15);
    }
  }
}
