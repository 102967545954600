.suggestion-doctor {
  &__btn.MuiButtonBase-root {
    background-color: transparent;
    color: #263238;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  &__menu-title {
    color: #263238;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.75;
    position: absolute;
  }

  &__menu-header {
    display: flex;
    padding: 0px 24px;
    margin-bottom: 12px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 24px;
    position: relative;
  }

  &__menu-wrapper {
    min-width: 300px;
  }
}
