.month-year-picker {
  &__btn.MuiButtonBase-root {
    background: transparent;
    padding-right: 0px;
    padding-left: 6px;
    margin-left: 8px;
    color: black;
    &:hover {
      background-color: $color-substrate;
    }
  }

  &__btn.--error.MuiButtonBase-root {
    color: $color-accent;
  }
}
