.task-button {
  position: relative;
  &__handshake {
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    @media screen and (max-width: $media-sm) {
      width: 28px;
      height: 28px;
    }
  }
  &__wrapper.handshake {
    background-color: rgba(38, 50, 56, 0.03);
  }
  &__wrapper.envelope-progress {
    background-color: $color-accent;
  }
  &__wrapper.envelope-done {
    background-color: #2f875d;
  }

  &__wrapper.meetup-progress {
    background-color: $color-accent;
  }
  &__wrapper.meetup-done {
    background-color: #2f875d;
  }

  &__icon {
    width: 18px;
    height: 18px;
    fill: white;
    @media screen and (max-width: $media-xsm) {
      width: 16px;
      height: 16px;
    }
  }

  &__count {
    position: absolute;
    font-size: 8px;
    background: black;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
  }

  &__task-type {
    color: #263238;
    font-size: 15px;
    font-weight: 600;
    line-height: 14px;
  }

  &__task-date {
    color: #263238;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.75;
  }
}
