.add-speciality-form {
  position: relative;
  height: 100%;

  &__heading {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close-modal {
    display: none;

    @media screen and (max-width: 450px) {
      display: block;
    }
  }

  &__form-input {
    margin-bottom: 16px;
  }

  &__buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__cancel.MuiButtonBase-root.MuiButton-root {
    margin-right: 16px;
    width: 141px;
    background-color: #efefef;
    color: #263238;
  }

  &__save.MuiButtonBase-root.MuiButton-root {
    width: 141px;
    background-color: #263238;
  }
}