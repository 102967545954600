.task-variant-filter {
  &__filter-icon {
    width: 18px;
    height: 18px;
    fill: black;
  }

  &__alphabet-btn {
    font-weight: 800;
    font-size: 11px;
    color: #263238;
    opacity: 0.9;
  }
  &__alphabet-menu-filter {
    font-weight: 600;
    font-size: 11px;
    line-height: 8px;
    margin-right: 9px;
    color: #263238;
    opacity: 0.9;
  }

  &__filter-icon-menu {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-left: 3px;
    fill: black;
  }
}
