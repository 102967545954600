.history-report-page {
  display: flex;
  width: 100%;
  min-height: 100vh;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 32px;

    @media screen and (max-width: $media-xsm) {
      padding: 0 12px;
    }
  }

  &__sidebar {
    @media screen and (max-width: $media-sm) {
      display: none;
    }
  }

  &__title-container {
    position: relative;
    height: 84px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -32px;
      background-color: rgba(black, 0.15);
      width: calc(100% + 64px);
      height: 1px;
    }
  }

  &__title {
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  &__burger-menu {
    margin-right: 12px;
    display: none;

    @media screen and (max-width: $media-sm) {
      display: block;
    }
  }
}
