.app-autocomplete {
  position: relative;

  & .MuiAutocomplete-root {
    //margin-bottom: 8px;
  }

  &__error {
    font-size: 12px;
    color: red;
  }

  &__autocomplete.MuiAutocomplete-root {
    .Mui-disabled {
      .MuiInputBase-input {
        border: 1px solid #BDBDBD;
        background-color: white;
        -webkit-text-fill-color: rgba(0, 0, 0, 1);
      }
    }
  }

  &__error-icon {
    position: absolute;
    top: 50%;
    right: 36px;
    z-index: 10;
    transform: translateY(-50%);
  }

  &__label {
    position: absolute;
    top: -6px;
    left: 13px;
    padding: 0 4px;
    z-index: 5;
    font-size: 11px;
    background-color: white;
    color: rgba(0, 0, 0, 0.38);
  }
}