.dynamic-table {
  display: flex;
  flex-direction: column;
  // padding-bottom: 24px;
  height: 100%;

  &__content {
    flex: 1 0;
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: flex;
    align-items: center;
    font-size: 17px;
    font-weight: 500;
  }

  &__burger-menu {
    margin-right: 12px;
    display: none;

    @media screen and (max-width: $media-sm) {
      display: block;
    }
  }

  &__header-container {
    position: relative;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -32px;
      background-color: rgba(black, 0.15);
      width: calc(100% + 64px);
      height: 1px;
    }
  }

  &__title {
    font-size: 17px;
    font-weight: 500;
  }

  &__period-container {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.75);
  }

  &__period.MuiButtonBase-root {
    background: transparent;
    padding-right: 4px;
    color: black;
    &:hover {
      background-color: $color-substrate;
    }
  }

  &__tabs-wrapper {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(black, 0.15);
    // margin-bottom: 24px;
  }

  &__filters {
    display: flex;
    gap: 18px;
  }

  &__table.MuiTableContainer-root {
    margin-top: 16px;
    flex: 1;
    white-space: nowrap;
    width: calc(100vw - 285px - 64px);
    @media screen and (max-width: $media-sm) {
      width: calc(100vw - 64px);
    }
    @media screen and (max-width: $media-xsm) {
      width: calc(100vw - 24px);
    }
  }

  &__pagination {
    & .MuiPagination-ul {
      justify-content: flex-end;
    }
  }
}
