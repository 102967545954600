.failure-commentary {
  //   flex: 1 0;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: height 0.12s ease-in-out;

  &__title {
    font-weight: 700;
    font-size: 12px;
    opacity: 0.75;
  }
  &__input.MuiFormControl-root {
    flex: 1 0;
    // height: 100%;
    // max-height: 100%;
    margin-bottom: 8px;
    width: 100%;
    transition: height 0.12s ease-in-out;

    & .MuiInputBase-root {
      height: 100%;
      padding: 0;
      // max-height: 100%;
      transition: height 0.12s ease-in-out;
      ::placeholder {
        padding-left: 4px;
      }
      & .MuiInputBase-input {
        background-color: transparent;
        border-radius: 0;
        height: 100% !important;
        transition: height 0.12s ease-in-out;
      }
    }
  }
}
