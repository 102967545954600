.completed-meetups {
  flex: 1 0;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  &__list-container {
    flex: 1 0;
    // max-height: calc(100vh - 188px);
    // overflow-y: scroll;
  }

  &__pagination {
    padding: 16px 16px 16px 16px;
    & .MuiPagination-ul {
      justify-content: flex-end;
    }
  }

  .meetup-history-item {
  }
}
