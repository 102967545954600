.input {
  position: relative;
  background-color: #f3f3f3;
  border-radius: 6px;


  &__close {
    display: none !important;
  }

  &:hover {
    & .input__close {
      display: block !important;
    }
  }

  & .MuiInputBase-input.Mui-disabled {
    background-color: white;
    height: 29px;
  }

  &__field.MuiFormControl-root.MuiTextField-root {
    width: 100%;
  }

  & .input__wrapper {
    background-color: #f3f3f3;
    border-radius: 6px;
  }
  & .input__label {
    position: absolute;
    top: -6px;
    left: 13px;
    padding: 0 4px;
    z-index: 5;
    font-size: 11px;
    background-color: white;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.38);
  }

  &_disabled {
    & .input__wrapper {
      & .MuiFormControl-root.MuiTextField-root {
        border: 1px solid #bdbdbd;
        background-color: white;
        border-radius: 6px;
      }
    }

    & .MuiInputBase-root {
      & .MuiInputBase-input {
        -webkit-text-fill-color: rgba(0, 0, 0, 1);
      }
    }
  }
}
