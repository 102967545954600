.birthday-label {
  font-weight: 800;
  font-size: 10px;
  display: flex;
  align-items: center;
  color: #ffffff;
  background: #ec6d62;
  border-radius: 12px;
  padding: 4px;
  gap: 2px;
  white-space: nowrap;
}
