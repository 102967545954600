.contacts-mobile-form {
  padding: 24px 16px;
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
    position: relative;
  }

  &__title {
    color: #263238;
    font-size: 17px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__close.MuiButtonBase-root {
    padding: 0;
    background: transparent;
    color: #2574d0;
    font-size: 17px;
    font-weight: 600;
    &:hover {
      background: transparent;
    }
  }
  &__input {
    width: 100%;
    border-radius: 0;
    margin-bottom: 1px;
  }
  &__input-wrapper {
    display: flex;
    align-items: center;
    background-color: #f3f3f3;
    margin-bottom: 1px;
    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      & .contacts-mobile-form__input {
        border-top-left-radius: 6px;
      }
    }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      & .contacts-mobile-form__input {
        border-bottom-left-radius: 6px;
      }
    }
  }

  &__delete-btn {
    background-color: transparent;
    &:hover {
      background-color: darken(#f3f3f3, 3);
    }
    & .MuiSvgIcon-root {
      width: 12px;
      height: 12px;
    }
  }

  &__phone-btn.MuiButtonBase-root {
    border-radius: 0;
    margin-bottom: 1px;
    &:first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  &__add.MuiButtonBase-root {
    width: 100%;
    border-radius: 6px;
    color: black;
    opacity: 0.8999999761581421;
    background: rgba(38, 50, 56, 0.03);
    justify-content: flex-start;
    gap: 8px;
    color: #263238;
    font-size: 12px;
    font-weight: 700;
    margin-top: 16px;
    height: 41px;
    &:hover {
      background: rgba(38, 50, 56, 0.05);
    }
  }
}
