.search-bar {
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 100px;
  background: rgba(#fff, 0.75);
  padding-left: 8px;
  color: #263238;
  transition: 0.2s ease;

  &__search-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  &__input {
    width: 100%;
    height: 100%;
    border: 0;
    color: currentColor;
    background: transparent;

    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    outline: none;
  }

  &__round-button-icon.MuiSvgIcon-root {
    width: 12px;
    height: 12px;
  }

  &__round-button {
    background-color: transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}
