.task-complete-indicator {
  &__img-wrapper {
    min-width: 20px;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  &__icon {
    width: 12px;
    height: 12px;
  }
}
