.table-card {
  display: inline-flex;
  // height: 62px;
  max-width: 166px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
  border-radius: 8px;
  cursor: pointer;

  &__title {
    align-self: flex-start;
    font-weight: 700;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 90%;
    text-overflow: ellipsis;
  }

  &__sub-title {
    font-size: 10px;
    font-weight: 700;
    color: rgba(black, 0.5);
    white-space: nowrap;
    overflow: hidden;
    max-width: 90%;
    text-overflow: ellipsis;
  }

  &_active {
    background-color: rgba(#2370c9, 0.15);
  }

  &__doctor-subinfo {
    margin-top: 8px;
    @include text-truncate(1);
  }
}
