.users-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__tabs.MuiTabs-root {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.15);
      width: 100%;
      height: 1px;
    }
  }
  &__title-container {
    position: relative;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -32px;
      background-color: rgba(black, 0.15);
      width: calc(100% + 64px);
      height: 1px;
    }
  }

  &__burger-menu {
    margin-right: 12px;
    display: none;

    @media screen and (max-width: $media-sm) {
      display: block;
    }
  }

  &__title {
    font-size: 17px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  &__app-modal {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 500px;
    width: 100%;
    left: auto !important;

    .app-modal__container {
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
      width: 500px;
      height: 100%;
      background-color: white;
      padding: 24px;
      transform: none;
      box-shadow: 15px 0 40px -5px rgba(0, 0, 0, 0.3);

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

  &__list {
    flex: 1 1;
    height: 100%;
  }
  &__pagination-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 24px;
  }
}
