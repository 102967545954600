.switch {
  &__root.MuiSwitch-root {
    padding: 0;
    height: 15px;
    width: 26px;
  }

  &__thumb.MuiSwitch-thumb {
    box-shadow: none;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    transform: translateY(-4.5px);
    background-color: #263238;
    opacity: 0.5;
  }

  &__thumb.MuiSwitch-thumb.checked {
    background-color: #2574d0 !important;
    opacity: 1;
  }

  &__track.MuiSwitch-track {
    background-color: transparent;
    opacity: 0.5;
    border: 2px solid #263238;
  }
  &__track.MuiSwitch-track.checked {
    background-color: transparent !important;
    border: 2px solid #2574d0;
    opacity: 1 !important;
  }
  &__switch-base.MuiSwitch-switchBase {
    transform: translateX(-5px) !important;
    background-color: transparent;
  }
  &__switch-base.MuiSwitch-switchBase.Mui-checked {
    transform: translateX(7px) !important;
  }
}
