.tasks-list {
  height: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $media-sm) {
    padding: 16px 0;
  }
  &__list {
    flex: 1 0;
  }

  &__pagination {
    & .MuiPagination-ul {
      justify-content: flex-end;
    }
  }

  &__tabs.MuiTabs-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
}
