.reference-catalog-page {
  display: flex;
  width: 100%;
  min-height: 100vh;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 32px;

    @media screen and (max-width: $media-xsm) {
      padding: 0 12px;
    }
  }

  &__sidebar {
    @media screen and (max-width: $media-sm) {
      display: none;
    }
  }

  &__title-wrapper {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 17px;
    font-weight: 500;

    @media screen and (max-width: $media-xsm) {
      font-size: 16px;
    }
  }

  &__burger-menu {
    margin-right: 12px;
    display: none;

    @media screen and (max-width: $media-sm) {
      display: block;
    }
  }

  &__title-container {
    position: relative;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -32px;
      background-color: rgba(black, 0.15);
      width: calc(100% + 64px);
      height: 1px;
    }
  }

  &__app-modal {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 500px;
    width: 100%;
    left: auto !important;

    .app-modal__container {
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
      width: 500px;
      height: 100%;
      background-color: white;
      padding: 24px;
      transform: none;
      box-shadow: 15px 0 40px -5px rgba(0, 0, 0, 0.3);

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

  &__app-middle-modal.MuiModal-root {
    z-index: 1303;
    overflow: hidden;
    .app-modal__container {
      overflow: scroll;

      padding: 0;
      max-width: 375px;
      height: 620px;
      width: 100%;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      @media screen and (max-width: $media-sm) {
        max-width: 100%;
        height: 100%;
        top: 0;
      }
    }
  }

  &__tab-btn.MuiButtonBase-root {
    background: transparent;
    color: black;
    font-size: 17px;
    font-weight: 500;
    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }
  }
}
