.upload-result {
  position: absolute;
  right: 0;
  display: flex;
  background: rgba(47, 135, 93, 0.3);
  padding: 12px 24px;
  padding-right: 0;
  align-items: center;
  height: 41px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 6px;

  &__result {
    opacity: 0.9;
    color: #263238;
  }

  &__close-btn.MuiButtonBase-root {
    margin-left: 24px;
  }

  &__close-icon.MuiSvgIcon-root {
    width: 12px;
    height: 12px;
  }
}
