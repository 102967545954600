.footer {
  font-size: 12px;
  padding: 27px 0;
  text-align: center;

  &__link {
    color: black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
