.app-date-picker {
  position: relative;
  background-color: #F3F3F3;
  border-radius: 6px;

  &__mui-date-picker {
    width: 100%;

    & .MuiInputBase-input {
      height: 31px;
      padding: 8.5px 14px;
    }
  }

  &_disabled {
    background-color: white;
    border: 1px solid #BDBDBD;
    border-radius: 6px;

    & .MuiInputBase-input {
      background-color: white;
      color: rgba(0, 0, 0, 0.38);
    }
  }

  &__label {
    position: absolute;
    top: -6px;
    left: 13px;
    padding: 0 4px;
    z-index: 5;
    font-size: 11px;
    background-color: white;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.38);
  }

  &__custom-action-bar {
    padding: 0 16px 16px 16px;
    display: flex;
    justify-content: flex-end;
  }

  &__clear.MuiButtonBase-root.MuiButton-root {
    margin-right: 8px;
    margin-left: auto;
    background-color: #efefef;
    color: #263238;
  }

  &__accept.MuiButtonBase-root.MuiButton-root {
    background-color: #263238;
  }
}