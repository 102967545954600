.app-modal {
  &__container {
    position: absolute;
    left: 50%;
    top: 80px;
    width: 500px;
    height: 600px;
    transform: translateX(-50%);
    background-color: white;
    border: none;
    outline: none;
    padding: 16px;
  }
}