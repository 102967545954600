.add-doctors-tasks-modal {
  position: relative;
  height: 100%;

  &__heading {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close-modal {
    @media screen and (max-width: 450px) {
      display: block;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__cancel.MuiButtonBase-root.MuiButton-root {
    margin-right: 16px;
    width: 141px;
    background-color: #efefef;
    color: #263238;
  }

  &__create.MuiButtonBase-root.MuiButton-root {
    width: 141px;
    background-color: #263238;
  }

  &__form-input {
    margin-bottom: 16px;
  }

  &__summary {
    border-radius: 8px;
    border: #c7c7c7 1px solid;
    padding: 14px;
    font-size: 12px;
  }

  &__doctors-count-container {
    margin-bottom: 16px;
  }

  &__summary-text {
    color: rgba(black, 0.5)
  }

  &__summary-filter {
    margin-bottom: 8px;
  }

  &__summary-filter-title {
    margin-bottom: 8px;
  }
}