.send-envelope-form {
  padding: 32px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: height 0.12s ease-in-out;
  &.completed {
    padding-bottom: 8px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-weight: 700;
    font-size: 17px;
    color: #263238;
  }

  &__close-btn.MuiButtonBase-root {
    padding: 0;
    background-color: transparent;
    color: $color-action;
    font-weight: 600;
    font-size: 15px;
    &:hover {
      background-color: transparent;
    }
  }

  &__participants {
    font-weight: 700;
    font-size: 12px;
    color: #263238;
    opacity: 0.75;
    margin-top: 8px;
    margin-bottom: 24px;
  }

  &__content {
    height: 100%;
    display: flex;
    flex: 1 0;
    flex-direction: column;
  }

  &__content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }

  &__confirm.MuiButtonBase-root {
    min-height: 44px;
    background: rgba(37, 116, 208, 0.75);
    font-weight: 700;
    font-size: 15px;
    color: #ffffff;
  }

  &__failure.MuiButtonBase-root {
    min-height: 44px;
    background-color: #263238;
    opacity: 0.75;
    border-radius: 6px;
    &:hover {
      background-color: black;
    }
  }

  &__input.MuiFormControl-root {
    height: 100%;
    max-height: 100%;
    margin-bottom: 8px;
    transition: height 0.12s ease-in-out;

    & .MuiInputBase-root {
      height: 100%;
      padding: 0;
      max-height: 100%;
      transition: height 0.12s ease-in-out;
      ::placeholder {
        padding-left: 4px;
      }
      & .MuiInputBase-input {
        background-color: transparent;
        border-radius: 0;
        height: 100% !important;
        transition: height 0.12s ease-in-out;
      }
    }
  }
  &__delete-result.MuiButtonBase-root {
    background: transparent;
    color: $color-accent;
    min-height: 44px;
    margin-top: 4px;
    &:hover {
      background: transparent;
    }
  }
}
