.executors-select {
  &__btn.MuiButtonBase-root {
    width: 100%;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background-color: rgba(38, 50, 56, 0.03);
    border-radius: 8px;
    color: black;
    margin-bottom: 12px;
    &:hover {
      background-color: rgba(38, 50, 56, 0.05);
    }
  }

  &__placeholder {
    font-weight: 400;
    font-size: 15px;
    opacity: 0.5;
  }

  &__choose-label {
    padding: 4px;
    display: flex;
    padding-right: 0;
    align-items: center;
    color: black;
    font-weight: 400;
    font-size: 15px;
    color: #263238;
    opacity: 0.9;
    background-color: transparent;
  }
  &__executors-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__executor {
    width: 32px;
    height: 32px;
    font-size: 12px;
  }
}
