.check-box__disabled.MuiButtonBase-root.Mui-disabled {
  color: white;
}

.check-box.MuiButtonBase-root {
}

.check-box {
  display: flex;
  align-items: center;

  &__label {
    color: #263238;
    opacity: 0.9;
  }

  &__unchecked-icon.MuiSvgIcon-root {
    color: transparent;
    border-radius: 4px;
    background-color: $color-substrate;
  }

  &__checked-disabled-icon.MuiSvgIcon-root {
    color: black;
  }

  &__checked-icon.MuiSvgIcon-root {
    color: black;
    // margin-left: 4px;
    border-radius: 4px;

    background-color: $color-substrate;
  }
}
