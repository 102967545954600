.note-mobile-form {
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  &__title {
    font-weight: 700;
    font-size: 17px;
    color: #263238;
  }
  &__cancel.MuiButtonBase-root {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #2574d0;
    background-color: transparent;
    padding: 0;
    &:hover {
      color: #2574d0;
      background-color: transparent;
    }
  }
  &__save.MuiButtonBase-root {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #2574d0;
    background-color: transparent;
    justify-content: flex-end;
    padding: 0;

    &:hover {
      color: #2574d0;
      background-color: transparent;
    }
  }

  &__input-wrapper {
    height: 100%;
  }

  &__input {
    height: 100%;
    background-color: transparent;
    & .input__wrapper {
      height: 100%;
      background-color: transparent;
      & .MuiFormControl-root {
        flex: 1 0;
        height: 100%;
        max-height: 100%;
        margin-bottom: 8px;
        width: 100%;
        transition: height 0.12s ease-in-out;

        & .MuiInputBase-root {
          height: 100%;
          padding: 0;
          max-height: 100%;
          transition: height 0.12s ease-in-out;
          ::placeholder {
            padding-left: 4px;
          }
          & .MuiInputBase-input {
            background-color: transparent;
            border-radius: 0;
            height: 100% !important;
            transition: height 0.12s ease-in-out;
          }
        }
      }
    }
  }
}
