.edit-doctor-form {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  padding-right: 8px;

  @media screen and (max-width: 450px) {
    padding-top: 34px;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @media screen and (max-width: 450px) {
      align-items: flex-start;
    }
  }

  &__close-modal {
    @media screen and (max-width: 450px) {
      display: block;
    }
  }

  &__heading-controls {
    display: flex;
  }

  &__title-container {
    display: flex;

    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }

  &__title {
    margin-right: 16px;

    @media screen and (max-width: 450px) {
      margin-bottom: 16px;
    }
  }

  &__status {
    margin-right: auto;

    @media screen and (max-width: 450px) {
      margin-right: 0;
    }
  }

  &__menu {
    margin-right: 10px;
  }

  &__edit {
    margin-right: 10px;
  }

  &__form-input {
    margin-bottom: 16px;
    flex: 1;
  }

  &__notice {
    margin-bottom: 16px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 16px;
  }

  &__phone-input.edit-doctor-form__input.input_disabled,
  &__location-input.edit-doctor-form__input.input_disabled {
    & .MuiInputBase-input.Mui-disabled {
      border-bottom: none;
      border-radius: 6px 6px 0 0;
    }
  }

  &__email-input.edit-doctor-form__input.input_disabled,
  &__address-input.edit-doctor-form__input.input_disabled {
    & .MuiInputBase-input.Mui-disabled {
      border-radius: 0 0 6px 6px;
    }
  }

  &__phone-input.edit-doctor-form__input,
  &__location-input.edit-doctor-form__input {
    & .MuiInputBase-input {
      border-bottom: 1px solid white;
      border-radius: 6px 6px 0 0;
    }
  }

  &__email-input.edit-doctor-form__input,
  &__address-input.edit-doctor-form__input {
    & .MuiInputBase-input {
      border-radius: 0 0 6px 6px;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__cancel.MuiButtonBase-root.MuiButton-root {
    margin-right: 16px;
    width: 141px;
    background-color: #efefef;
    color: #263238;
  }

  &__save.MuiButtonBase-root.MuiButton-root {
    width: 141px;
    background-color: #263238;
  }

  &__lpu-container,
  &__phones-container {
    display: flex;
  }

  &__lpu-input {
    flex: 1;
  }

  &__add-lpu,
  &__remove-lpu,
  &__add-phone,
  &__remove-phone {
    transform: translateY(6px);
  }

  &__form {
    overflow-y: scroll;
    padding: 10px 10px 10px 0;
  }

  &__add-lpu,
  &__remove-lpu,
  &__add-phone,
  &__remove-phone {
    margin-left: 8px;
  }

  &__meetups-title {
    font-size: 15px;
    font-weight: 600;
  }

  &__meetups-accordion.MuiPaper-root {
    box-shadow: none;
    padding-right: 10px;

    &:before {
      height: 0;
    }
  }

  &__meetups-summary.MuiButtonBase-root {
    background-color: #f7f7f7;
    border-radius: 8px;
    max-height: 48px;
  }

  &__meetups-summary.MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;

    & .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }
  }

  &__meetup-card {
    margin-bottom: 8px;
  }

  &__pagination-container {
    display: flex;
    justify-content: flex-end;
  }

  &__meetup-empty {
    font-size: 14px;
    padding-left: 14px;
  }

  &__create-meetup.MuiButtonBase-root {
    background-color: transparent;
    color: $color-action;
    display: block;
    margin-left: auto;

    &:hover {
      background-color: rgba($color-action, 0.06);
    }
  }
}
