.dynamic-table-row {
  &__name {
    font-weight: 700;
    font-size: 15px;
    color: #263238;
    opacity: 0.9;
    text-wrap: wrap;
  }

  &__dynamic {
    font-weight: 500;
    font-size: 15px;
    color: #2f875d;
    opacity: 0.95;
    position: relative;
    &.negative {
      color: #ff4c06;
    }
  }

  &__summary {
    font-weight: 500;
    font-size: 15px;
    color: #263238;
    opacity: 0.95;
  }

  &__dynamic-indicator.MuiSvgIcon-root {
    position: absolute;
    left: -20px;
    top: -2px;
    &.positive {
      transform: rotate(180deg);
    }
  }
}
