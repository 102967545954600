.feedback-item {
  border-radius: 6px;
  padding: 20px 0;
  display: flex;
  cursor: pointer;
  position: relative;
  width: calc(100vw - 64px - 285px);

  &_current {
    background-color: rgba(37, 116, 208, 0.15);
  }

  @media screen and (max-width: $media-sm) {
    width: calc(100vw - 64px);
  }

  @media screen and (max-width: $media-xsm) {
    width: calc(100vw - 24px);
  }

  &__indicator {
    min-width: 32px;
    position: relative;
  }

  &__indicator.done {
    &::before {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      top: 5px;
      background-color: #2574d0;
    }
  }

  &__info {
    width: 100%;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    @media screen and (max-width: $media-sm) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__subinfo {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: calc(100vw - 32px - 64px - 285px);

    @media screen and (max-width: $media-sm) {
      width: calc(100vw - 32px - 64px);
    }

    @media screen and (max-width: $media-xsm) {
      width: calc(100vw - 32px - 24px);
    }
  }

  &__doctor-info {
    margin-right: 8px;
    @include text-truncate(1);
  }

  &__doctor-name {
    color: #263238;
    font-size: 15px;
    font-weight: 700;
    opacity: 0.9;
    margin-bottom: 4px;
  }

  &__author-info {
    white-space: nowrap;
    display: flex;
    color: #263238;
    font-size: 10px;
    font-weight: 700;
    align-items: center;
    opacity: 0.9;
  }

  &__author-fullname {
    margin-right: 4px;
    @media screen and (max-width: $media-sm) {
      display: none;
    }
  }

  &__rounded-label {
    display: none;
    @media screen and (max-width: $media-sm) {
      display: flex;
    }
  }

  &__note-icon {
    opacity: 0.75;
  }

  &__content {
    padding: 16px 24px;
    border-radius: 6px;
    background: rgba(#ec6d62, 0.1);
    position: relative;
  }

  &__doctor {
    margin-bottom: 12px;
  }

  &__feedback {
    color: #263238;
    font-size: 15px;
    font-weight: 400;
    opacity: 0.75;
  }

  &__checkbox-wrapper {
    position: relative;
    min-width: 32px;
  }

  &__checkbox.MuiButtonBase-root {
    position: absolute;
    right: 0;
    top: -8px;
  }

  &:hover {
    background-color: rgba(37, 116, 208, 0.15);
  }

  &__type {
    position: absolute;
    left: 24px;
    top: -9px;
    padding: 1px 4px;
    color: rgba(#263238, 0.75);
    font-size: 11px;
    font-weight: 700;
    background-color: white;
    border-radius: 6px;
  }
}
