.meetup-skeleton {
  padding: 12px 0;
  position: relative;

  &__name.MuiSkeleton-root {
    max-width: 400px;
    height: 14px;
    width: 100%;
    margin-bottom: 14px;
  }

  &__info.MuiSkeleton-root {
    max-width: 120px;
    height: 10px;
    width: 100%;
    margin-bottom: 4px;
  }

  &::after {
    position: absolute;
    bottom: 0px;
    content: '';
    height: 1px;
    width: 100%;
    background: black;
    opacity: 0.15;
  }
  &:last-child {
    &::after {
      height: 0;
    }
  }
}
