.history-item {
  // margin-bottom: 32px;
  padding: 16px 8px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 15px;
    opacity: 0.9;
  }
  &__info-wrapper {
    opacity: 0.5;
    font-weight: 700;
    font-size: 10px;
  }

  &__buttons-wrapper {
    opacity: 0;
    height: 24px;
    display: flex;
    gap: 8px;
    @media screen and (max-width: $media-sm) {
      opacity: 1;
    }
  }

  &__report.MuiButtonBase-root {
    padding: 0;
    font-weight: 600;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.75);
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 40px;
    display: flex;
    align-items: center;
    gap: 4px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  &__create-task.MuiButtonBase-root {
    padding: 0 8px;
    font-weight: 600;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.75);
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 40px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  &::after {
    position: absolute;
    bottom: 0;
    content: '';
    height: 0px;
    width: calc(100% + 8px);
    right: -8px;
    background: black;
    opacity: 0.15;
  }

  &:hover {
    background-color: rgba(35, 112, 201, 0.15);
    .history-item__buttons-wrapper {
      opacity: 1;
    }
  }

  &:last-child {
    &::after {
      height: 0;
    }
  }

  &__cancel-download {
    height: 24px;
    width: 24px;
    min-width: 24px;
    background-color: rgba(0, 0, 0, 0.04);

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }
}
