.meetup-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0;

  &__list {
    flex: 1 0;
    position: relative;

    @media screen and (max-width: $media-xsm) {
      max-height: calc(100vh - 84px);
      overflow-y: scroll;
    }
  }

  &__scroll-progress {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(#f4f5f9, 0.4);
  }

  &__pagination.MuiPagination-root {
    & .MuiPagination-ul {
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: $media-xsm) {
    // margin-top: 16px;
    padding-top: 0;
    padding: 0;
  }

  // & .meetup-item {

  // }
}
