.app-select {
  position: relative;

  &__label {
    position: absolute;
    top: -6px;
    left: 13px;
    padding: 0 4px;
    z-index: 5;
    font-size: 11px;
    background-color: white;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.38);
  }

  &__placeholder {
    color: #c0c0c0;
  }

  &__menu-item-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__help-text {
    color: #959595;
    font-size: 12px;
  }
}