.task-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  cursor: pointer;

  &:hover {
    & .task-item__round-menu {
      display: flex;
    }
  }

  &__wrapper {
    display: flex;
    // align-items: center;
    gap: 14px;
  }

  &__title {
    font-weight: 700;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.9);
    opacity: 0.9;
    white-space: nowrap;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__title-wrapper {
    display: flex;
    gap: 24px;
  }

  &__sub-info {
    font-weight: 700;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__task-labels {
    display: flex;
    align-items: center;
  }

  &__new-label {
    height: 64px;
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(38, 50, 56, 0.03);
    font-weight: 700;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  &__description-wrapper {
    display: flex;
    align-items: center;
    gap: 2px;
    margin-bottom: 4px;
  }

  &__description {
    font-weight: 400;
    font-size: 15px;
    opacity: 0.75;
    @include text-truncate(1);
    word-break: break-all;
  }

  &__task-icon {
    min-width: 16px;
  }

  &__statistic {
    text-align: end;
  }

  &__count {
    color: #000;
    text-align: right;
    font-size: 15px;
    font-weight: 700;
    opacity: 0.75;
  }

  &__complete-date-wrapper {
    margin-left: 16px;
    display: flex;
    align-items: center;
    gap: 2px;
    @media screen and (max-width: $media-sm) {
      margin-left: 4px;
    }
  }

  &__complete-date {
    color: #000;
    font-size: 10px;
    font-weight: 700;
    opacity: 0.5;
    white-space: nowrap;
  }

  &:hover {
    background-color: rgba(35, 112, 201, 0.15);
  }
  // &::after {
  //   position: absolute;
  //   bottom: 0px;
  //   content: '';
  //   height: 1px;
  //   width: 100%;
  //   background: black;
  //   opacity: 0.15;
  // }
  // &:last-child {
  //   &::after {
  //     height: 0;
  //   }
  // }
  & .rounded-label {
    min-width: 18px;
    font-size: 12px;
  }

  &__rounded-label {
    width: 32px;
    height: 32px;
  }

  &__round-menu {
    margin-right: 4px;
    display: none;
  }
}
