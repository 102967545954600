.delete-confirm-menu {
  &__menu-content {
    padding: 16px;
    padding-bottom: 8px;
    width: 100%;
    max-width: 300px;
  }

  &__cancel-text {
    line-height: 20px;
    font-weight: 500;
    font-size: 15px;
    color: #263238;
    padding-bottom: 16px;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      opacity: 0.15;
      height: 1px;
      width: 100%;
      right: 0;
      background-color: #263238;
    }
  }

  &__cancel.MuiButtonBase-root {
    display: block;
    margin-left: auto;
    font-weight: 700;
    font-size: 15px;
    color: #ff4c06;
    background-color: transparent;
    margin-top: 8px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
  }

  &__list.MuiList-root {
    padding: 0;
  }
}
