.edit-lpu-form {
  position: relative;
  height: 100%;

  @media screen and (max-width: 450px) {
    padding-top: 34px;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @media screen and (max-width: 450px) {
      align-items: flex-start;
    }
  }

  &__close-modal {
    @media screen and (max-width: 450px) {
      display: block;
    }
  }

  &__heading-controls {
    display: flex;
  }

  &__title-container {
    display: flex;

    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }

  &__title {
    margin-right: 16px;

    @media screen and (max-width: 450px) {
      margin-bottom: 16px;
    }
  }

  &__status {
    margin-right: auto;

    @media screen and (max-width: 450px) {
      margin-right: 0;
    }
  }

  &__menu {
    margin-right: 10px;
  }

  &__edit {
    margin-right: 10px;
  }

  &__form-input {
    margin-bottom: 16px;
  }

  &__city.input_disabled {
    & .MuiInputBase-input.Mui-disabled {
      border-bottom: none;
      border-radius: 6px 6px 0 0;
    }
  }

  &__district.input_disabled {
    & .MuiInputBase-input.Mui-disabled {
      border-bottom: none;
      border-radius: 0 0 0 0;
    }
  }

  &__street.input_disabled {
    & .MuiInputBase-input.Mui-disabled {
      border-radius: 6px;
    }
  }

  &__city {
    & .MuiInputBase-input {
      border-bottom: 1px solid white;
      border-radius: 6px 6px 0 0;
    }
  }

  &__district {
    & .MuiInputBase-input {
      border-bottom: 1px solid white;
      border-radius: 0 0 0 0;
    }
  }

  &__street {
    & .MuiInputBase-input {
      border-radius: 6px;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__cancel.MuiButtonBase-root.MuiButton-root {
    margin-right: 16px;
    width: 141px;
    background-color: #efefef;
    color: #263238;
  }

  &__save.MuiButtonBase-root.MuiButton-root {
    width: 141px;
    background-color: #263238;
  }
}
