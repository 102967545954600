.history-report {
  &__table.MuiTable-root {
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    @media screen and (max-width: $media-lg) {
      table-layout: auto;
      width: 200%;
    }
  }
}
