.upload-history-list {
  display: flex;
  flex: 1 0;
  padding-bottom: 24px;
  flex-direction: column;
  &__list {
    flex: 1 0;
  }

  &__pagination {
    & .MuiPagination-ul {
      justify-content: flex-end;
    }
  }
  &__download-progress {
    z-index: 10;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    gap: 12px;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(#f4f5f9, 0.4);
  }
}
