.doctor-skeleton-loader {
  position: relative;
  display: flex;
  padding: 16px 0;
  gap: 18px;

  &__checkbox {
  }

  &__right-part {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__name {
    max-width: 300px;
  }

  &__info {
    max-width: 150px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    opacity: 0.15;
    height: 1px;
    width: calc(100% - 42px);
    right: 0;
    background-color: #263238;
  }
  &:last-child {
    &::after {
      height: 0;
    }
  }
}
