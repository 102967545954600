.doctor-search-button {
  background: rgb(241, 243, 243);
  border-radius: 14px;
  height: 32px;
  position: absolute;
  right: 6px;
  width: 100%;
  transition: 0.12s ease-in-out;

  display: flex;
  align-items: center;

  &__input-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  &__close-icon.MuiSvgIcon-root {
    width: 12px;
    height: 12px;
  }

  &__round-btn {
    background: rgb(241, 243, 243);
  }
  &__input {
    height: 32px;
    height: 32px;
    border: none;
    background: rgb(241, 243, 243);
    outline: none;
    padding-right: 36px;
    padding-left: 12px;
    width: 100%;
    transition: 0.12s ease-in-out;
    // & .input__wrapper {
    //   height: 32px;
    //   & .MuiFormControl-root {
    //     height: 32px;
    //     & .MuiInputBase-root {
    //       height: 32px;
    //       & .MuiInputBase-input {
    //         max-height: 32px;
    //         box-sizing: border-box;
    //       }
    //     }
    //   }
  }
}
