* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}

.MuiInputBase-root {
  .MuiInputBase-input {
    font-family: 'Nunito', sans-serif;
  }
}

.MuiButtonBase-root.MuiButton-root {
  font-family: 'Nunito', sans-serif;
}