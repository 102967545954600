.color-counter {
  background-color: black;
  display: inline-flex;
  color: white;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px;
  user-select: none;
  min-width: 26px;
  align-items: center;
  justify-content: center;
}