.create-envelope-task-popup {
  background-color: white;
  padding: 8px 16px;
  display: flex;
  justify-content: flex-end;

  &__text {
    cursor: pointer;
    color: $color-action;
    font-size: 14px;
  }
}