.upload-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 24px;
  &__header {
    position: relative;
    padding: 14px 0;
    height: 73px;
    display: flex;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.15);
      width: calc(100%);

      height: 1px;
    }
  }

  &__match-label {
    margin-left: 8px;
    font-size: 15px;
    color: #263238;
    opacity: 0.75;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    position: relative;
    align-items: center;
    &:after {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      background: black;
      opacity: 0.15;
      bottom: 0;
    }
    &:last-child {
      &::after {
        height: 0;
      }
    }
  }

  &__list {
    flex: 1 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    position: relative;
    align-items: center;
    &:after {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      background: black;
      opacity: 0.15;
      bottom: 0;
    }
  }

  &__controls-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__result-alert {
    position: relative;
  }

  &__header-title {
    font-weight: 700;
    font-size: 12px;
    color: #263238;
    opacity: 0.5;
    &:nth-child(1) {
      flex: 25%;
    }
    &:nth-child(2) {
      flex: 10%;
      margin-right: 20%;
      text-align: end;
    }
    &:nth-child(3) {
      flex: 55%;
      @media screen and (max-width: $media-md) {
        flex: 33%;
      }
    }
  }

  &__pagination {
    & .MuiPagination-ul {
      justify-content: flex-end;
    }
  }
}
