.task-detail-item {
  display: flex;
  padding: 12px 0;
  position: relative;

  &__checkbox {
    align-items: baseline;
    margin-right: 12px;
    justify-content: start;
    & .MuiButtonBase-root {
      padding: 0;
    }
  }

  &__info {
    position: relative;
    width: 100%;
    @media screen and (max-width: $media-sm) {
      padding-right: 32px;
    }
  }

  &__fullname {
    font-weight: 700;
    font-size: 15px;
    color: #263238;
    opacity: 0.9;
    margin-bottom: 8px;
    @include text-truncate();
    word-break: break-all;
  }

  &__subinfo {
    display: flex;
    gap: 8px;
    @include text-truncate(1);
  }

  &__user-info {
    font-weight: 700;
    font-size: 10px;
    color: #263238;
    opacity: 0.5;
    white-space: nowrap;
  }

  &__delete-btn {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    background: $color-substrate;
    opacity: 0;
    transition: 0.12s ease-in-out;
    @media screen and (max-width: $media-sm) {
      opacity: 1;
    }
  }

  &__delete-title {
    white-space: nowrap;
    padding: 10px 24px;
    position: relative;
    font-weight: 500;
    font-size: 15px;
    color: #263238;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      opacity: 0.15;
      height: 1px;
      width: calc(100% - 24px);
      right: 12px;
      background-color: #263238;
    }
  }

  &__delete-menu-btn.MuiButtonBase-root {
    color: $color-accent;
    text-align: center;
    justify-content: center;
    margin-top: 4px;
  }

  &__note-wrapper {
    display: flex;
    align-items: center;
  }

  &__note-icon {
    width: 10px;
    height: 10px;
    margin-right: 2px;
  }

  &__note {
    font-weight: 700;
    font-size: 10px;
    color: #263238;
    opacity: 0.75;
    @include text-truncate();
  }

  &:hover {
    & .task-detail-item__delete-btn {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    opacity: 0.15;
    height: 1px;
    width: calc(100% - 42px);
    right: 0;
    background-color: #263238;
  }
  &:last-child {
    &::after {
      height: 0;
    }
  }
}
