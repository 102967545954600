.dynamic-page {
  display: flex;
  width: 100%;
  min-height: 100vh;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 32px;

    @media screen and (max-width: $media-xsm) {
      padding: 0 12px;
    }
  }

  &__sidebar {
    @media screen and (max-width: $media-sm) {
      display: none;
    }
  }
}
