.reference-table {
  padding-top: 26px;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $media-xsm) {
    padding-top: 0;
    align-items: center;
  }

  &__tab-container {
    height: 49px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid rgba(black, 0.15);
    @media screen and (max-width: $media-xsm) {
      flex-direction: column-reverse;
      max-width: 90%;
      margin-bottom: 8px;
      border: 0;
    }
  }

  &__filters {
    display: flex;

    @media screen and (max-width: $media-xsm) {
      padding-top: 12px;
      margin-bottom: 8px;
    }
  }

  &__card.table-card {
    margin: 0 8px 8px 0;
    max-width: 100%;

    &:first-of-type {
      margin-top: 32px;
    }

    @media screen and (max-width: $media-xsm) {
      max-width: calc(100vw - 40px);
      padding-left: 8px;
    }
  }

  &__filter {
    margin-right: 16px;
    @media screen and (max-width: $media-xsm) {
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__content {
    //column-count: 3;
    //height: 100%;
    //column-fill: auto;

    @media screen and (max-width: $media-xsm) {
      //column-count: 2;
    }
  }

  &__content-wrapper {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    max-height: calc(100vh - 258px);
    width: 100%;

    @media screen and (max-width: $media-xsm) {
      max-height: calc(100vh - 296px);
    }

    &_admin {
      @media screen and (max-width: $media-xsm) {
        max-height: calc(100vh - 328px);
      }
    }
  }

  &__pagination-container {
    justify-self: flex-end;
    padding: 16px 16px 28px 16px;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $media-xsm) {
      padding: 18px 0 0 0;
    }
  }

  &__app-modal {
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: 500px;
    width: 100%;
    left: auto !important;

    .app-modal__container {
      position: absolute;
      right: 0;
      top: 0;
      left: auto;
      width: 500px;
      height: 100%;
      background-color: white;
      padding: 24px;
      transform: none;
      box-shadow: 15px 0 40px -5px rgba(0, 0, 0, 0.3);

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

  &__unactive-filter {
    padding: 0 24px 0 0;
    & .check-box {
      white-space: nowrap;
      flex-direction: row-reverse;
      justify-content: flex-start;
      font-size: 15px;
      font-weight: 600;
    }
  }

  &__search {
    @media screen and (max-width: $media-xsm) {
      display: none;
    }
  }

  &__mobile-search.search-button {
    margin-bottom: 12px;
    max-width: 321px;
    display: none;
    @media screen and (max-width: $media-xsm) {
      display: block;
    }

    & .search-button__input {
      height: 48px;
      font-size: 14px;
    }

    & .search-button__round-button {
      height: 48px;
      width: 48px;
    }
  }

  & .reference-skeleton {
    margin-top: 32px;
  }
}
