.upload-row {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  position: relative;
  align-items: center;
  &:after {
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    background: black;
    opacity: 0.15;
    bottom: 0;
  }
  &:last-child {
    &::after {
      height: 0;
    }
  }
  &__fullname {
    flex: 20%;
    font-weight: 700;
    font-size: 15px;
    color: #263238;
    opacity: 0.9;
  }
  &__amount {
    flex: 15%;
    text-align: end;
    margin-right: 20%;

    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #263238;
    opacity: 0.95;
  }

  &__local-fullname {
    flex: 55%;
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    position: relative;
    font-weight: 700;
    font-size: 15px;
    color: #263238;
    opacity: 0.9;
    @media screen and (max-width: $media-md) {
      flex: 33%;
    }
  }

  &__match-btn.MuiButtonBase-root {
    background-color: $color-substrate;
    &:hover {
      background-color: $color-substrate;
    }
  }

  &__doctor-fullname {
    color: #263238;
    font-size: 15px;
    font-weight: 600;
  }

  &__facility {
    color: #263238;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.75;
  }

  &__more-btn.MuiButtonBase-root {
    width: fit-content;
    justify-self: flex-end;
    position: absolute;
    right: 0;
    bottom: -8px;
    opacity: 0;
    transition: 0.12s ease-in-out;
  }
  &:hover {
    .upload-row__more-btn.MuiButtonBase-root {
      opacity: 1;
    }
  }
}
