.feedback-meetup-item {
  cursor: pointer;
  padding: 25px 12px 10px;
  border-radius: 6px;
  background-color: rgba(#ec6d62, 0.1);
  position: relative;
  margin-bottom: 5px;
  &:last-of-type {
    margin-bottom: 0;
  }

  &_comment {
    cursor: default;
    background-color: rgba(#2370c9, 0.1);
    padding: 16px 12px 12px 12px;
  }

  &_placeholder {
    padding: 16px 12px;
  }

  &__label {
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    background-color: white;
    padding: 0 6px;
    color: rgba(#263238, 0.75);
    font-size: 11px;
    font-weight: 700;
    line-height: normal;
    border-radius: 6px;
  }

  &__description {
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: 400;
    color: #263238;
    opacity: 0.75;
    &_placeholder {
      margin-bottom: 0;
    }
  }

  &__date {
    text-align: end;
    color: #263238;
    font-size: 10px;
    font-weight: 700;
    opacity: 0.75;
    margin-left: auto;
  }

  &__name {
    text-align: end;
    color: #263238;
    font-size: 10px;
    font-weight: 700;
    opacity: 0.75;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__show-comment {
    cursor: pointer;
    color: #263238;
    opacity: 0.75;
    background: transparent;
    border: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__expand-icon {
    &.MuiSvgIcon-root {
      width: 18px;
    }
    &_expanded {
      transform: rotate(180deg);
    }
  }
  &__spinner.MuiCircularProgress-root {
    & .MuiCircularProgress-svg {
      color: black;
    }
  }
}
