.changelog-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 84px);
  // padding-bottom: 16px;
  // padding: 16px 0;
  &__list {
    flex: 1 0;
    overflow-y: scroll;
    position: relative;
  }

  &__pagination.MuiPagination-root {
    padding: 16px;
    & .MuiPagination-ul {
      justify-content: flex-end;
    }
  }

  & .changelog-list-item,
  & .changelog-loader {
    &:first-of-type {
      margin-top: 16px;
    }
  }
}
