.subinfo-doctor-span {
  font-size: 12px;
  //   font-weight: 700;
  border-radius: 3px;
  background: rgba(black, 0.03);
  color: rgba(black, 0.75);
  display: inline-flex;
  align-items: center;

  padding: 4px 8px;
  margin-right: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  display: inline-flex;
  &__icon {
    opacity: 0.9;
    min-width: 10px;
    height: 10px;
    margin-right: 4px;
  }

  @media screen and (max-width: $media-sm) {
    font-size: 15px;
    font-weight: unset;
  }
}
