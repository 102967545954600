.meetup-task-skeleton {
  padding: 12px 0;
  position: relative;
  &__title.MuiSkeleton-root {
    height: 15px;
    max-width: 100px;
    width: 100%;
    margin-bottom: 11px;
  }

  &__date.MuiSkeleton-root {
    height: 11px;
    margin-bottom: 4px;
    max-width: 170px;
    width: 100%;
  }

  &::after {
    display: block;
    position: absolute;
    bottom: 0px;
    content: '';
    height: 1px;
    background: #263238;
    width: 100%;
    opacity: 0.15;
  }
  &:last-child {
    &::after {
      height: 0;
    }
  }
}
