.feedback-comment {
  margin-bottom: 16px;
  &__comment {
    padding: 12px;
    font-weight: 400;
    font-size: 15px;
    color: #263238;
    background: rgba(38, 50, 56, 0.03);
    border-radius: 6px;
    margin-bottom: 16px;
    word-break: break-word;
  }

  &__author {
    font-weight: 700;
    font-size: 11px;
    text-align: right;
    color: #263238;
    opacity: 0.75;
  }
}
