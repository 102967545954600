.task-skeleton-loader {
  position: relative;
  padding: 16px 0;
  &::after {
    position: absolute;
    bottom: 0px;
    content: '';
    height: 1px;
    width: 100%;
    background: black;
    opacity: 0.15;
  }
  &:last-child {
    &::after {
      height: 0;
    }
  }
}
