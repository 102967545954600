.fixed-header {
  background-color: #f4f5f9;

  &__cell.MuiTableCell-root {
    padding: 0;
  }

  &__filter-btn.MuiButtonBase-root {
    height: 100%;
    font-style: normal;
    font-weight: 700;
    width: 100%;
    font-size: 12px;
    background-color: transparent;
    color: rgba(#263238, 0.5);
    align-items: center;
    white-space: nowrap;
    padding: 0 8px;
    gap: 4px;
    justify-content: flex-start;
    margin-bottom: 10px;
    line-height: normal;
    &:hover {
      background-color: transparent;

      .fixed-header__sort-wrapper {
        opacity: 1;
      }
    }
  }

  &__sort-wrapper {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.2s ease;
    background-color: #c7c2c2;
    padding: 2px;

    &_current {
      opacity: 1;
      background-color: black;
    }
  }

  &__sort.MuiSvgIcon-root {
    width: 12px;
    height: 12px;
    color: white;
  }

  &__sort {
    &_current.MuiSvgIcon-root {
      color: white;
    }
  }

  &__default-cell {
    font-weight: 700;
    font-size: 12px;
    color: rgba(#263238, 0.5);
    padding: 0 8px;
    line-height: normal;
    margin-bottom: 10px;
  }

  &__sort {
    transition: 0.2s ease !important;
    &_asc {
      transform: scaleY(-100%);
    }
    &_desc {
      transform: scaleY(100%);
    }
  }
}
