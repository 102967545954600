.fullscreen-spinner {
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
  background-color: $color-background;

  &__spinner.MuiCircularProgress-root {
    & .MuiCircularProgress-svg {
      color: black;
    }
  }
}
