.reference-dynamic {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__dynamic-container {
    flex: 1 1;
  }

  &__wrapper {
    background-color: #f3f3f3;
    padding: 22px 14px;
    border-radius: 6px;
  }
  &__dynamic-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
  }

  &__dynamic-title {
    font-size: 12px;
    color: black;
    font-weight: 600;
  }

  &__dynamic-date-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__dynamic-date-title {
    font-size: 15px;
    font-weight: 400;
    color: #a3a3a3;
  }

  &__dynamic-values {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  &__clear.MuiButtonBase-root.MuiButton-root {
    margin-right: 16px;
    width: 141px;
    background-color: #efefef;
    color: #263238;
  }

  &__apply.MuiButtonBase-root.MuiButton-root {
    width: 141px;
    background-color: #263238;
  }
}
