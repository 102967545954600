.round-button {
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: darken(white, 3);
  }
}