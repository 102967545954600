.feedback-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
  }

  &__header-title {
    font-weight: 500;
    font-size: 17px;
    color: #000000;
  }

  &__info {
    padding: 12px 24px 24px;
    padding-top: 36px;
    overflow-y: auto;
  }

  &__content {
    padding: 16px 24px;
    border-radius: 6px;
    background: rgba(#ec6d62, 0.1);
    position: relative;
    margin-bottom: 8px;
  }

  &__type {
    position: absolute;
    left: 24px;
    top: -9px;
    padding: 1px 4px;
    color: rgba(#263238, 0.75);
    font-size: 11px;
    font-weight: 700;
    background-color: white;
    border-radius: 6px;
  }

  &__feedback {
    color: #263238;
    font-size: 15px;
    font-weight: 400;
    opacity: 0.75;
  }

  &__feedback-info {
    white-space: nowrap;
    color: #263238;
    font-size: 10px;
    font-weight: 700;
    text-align: end;
    opacity: 0.9;
    margin-bottom: 12px;
  }

  &__result-title {
    position: relative;
    font-weight: 500;
    font-size: 17px;
    padding-bottom: 10px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.15);
    }
  }

  &__confirmed-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__confirmed-label {
    font-weight: 500;
    font-size: 17px;
  }

  &__add-comment.MuiButtonBase-root {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 16px;
    gap: 8px;
    font-weight: 700;
    font-size: 12px;
    color: #263238;
    padding: 12px;
    background: rgba(38, 50, 56, 0.03);
    &:hover {
      background: rgba(38, 50, 56, 0.05);
    }
  }

  &__comment-list {
    flex: 1 0;
  }

  &__pagination {
    & .MuiPagination-ul {
      justify-content: flex-end;
    }
  }

  &__input-wrapper {
    position: relative;
    margin-bottom: 48px;
  }

  &__comment-input {
    & .input__wrapper {
      & .MuiInputBase-root {
        min-height: 45px;
        font-size: 14px;
        & .MuiInputBase-input.MuiOutlinedInput-input {
          min-height: unset;
        }
      }
    }
  }

  &__edit-buttons {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: -38px;
    display: flex;
    gap: 12px;
  }

  &__edit-btn {
    border-radius: 6px;
    opacity: 0.5;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.3);
  }

  &__title-container {
    padding: 16px 10px 20px 10px;
    background-color: rgba(#2f875d, 0.1);
    border-radius: 8px;
    margin-bottom: 16px;
  }

  &__feedback-comment {
    & .feedback-comment__comment {
      padding: 16px 10px 20px 10px;
      background-color: rgba(#2f875d, 0.1);
      border-radius: 8px;
    }
  }

  & .executor-select.MuiButtonBase-root {
    margin-bottom: 16px;
  }

  &__selectors {
    margin-bottom: 32px;
    display: flex;
    gap: 12px;
    position: relative;
    &::after {
      content: '';
      height: 1px;
      width: 100%;
      opacity: 0.15;
      background: #263238;
      position: absolute;
      bottom: -16px;
    }
  }

  &__status-select {
  }

  &__images-list {
    display: flex;
    overflow-x: auto;
    list-style: none;
    gap: 12px;
    padding-bottom: 18px;
  }

  &__image-wrapper {
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    background: none;
    border: none;
    cursor: pointer;
    display: block;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
}
