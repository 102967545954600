.history-filters {
  position: relative;
  height: 100%;

  &__heading {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close-modal {
    @media screen and (max-width: 450px) {
      display: block;
    }
  }

  &__form-input {
    margin-bottom: 16px;
    width: 100%;

    .input__field {
      width: 100%;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &__date-input.MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin-bottom: 16px;
    height: 48px;
    background-color: #f3f3f3;
    border-radius: 6px;

    .MuiInputBase-root.MuiOutlinedInput-root {
      height: 48px;

      .MuiInputBase-input.MuiOutlinedInput-input {
        min-height: 48px;
        box-sizing: border-box;
        padding-left: 12px;
      }
    }
  }

  &__clear.MuiButtonBase-root.MuiButton-root {
    margin-right: 16px;
    width: 141px;
    background-color: #efefef;
    color: #263238;
  }

  &__apply.MuiButtonBase-root.MuiButton-root {
    width: 141px;
    background-color: #263238;
  }

  &__customized-hook {
    max-height: 150px;
    overflow-y: scroll;
  }

  &__filters-container {
    max-height: calc(100vh - 165px);
    height: 100%;
    overflow-y: scroll;
    padding-right: 10px;
    padding-top: 10px;
    margin-bottom: 24px;
  }

  &__dynamic-container {
    background-color: #f3f3f3;
    padding: 22px 14px;
  }

  &__dynamic-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
  }

  &__dynamic-title {
    font-size: 12px;
    color: black;
    font-weight: 600;
  }

  &__dynamic-date-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__dynamic-date-title {
    font-size: 15px;
    font-weight: 400;
    color: #a3a3a3;
  }

  &__dynamic-values {
    display: flex;
    column-gap: 16px;
  }
}
