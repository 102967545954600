.logo {
  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    display: block;
    margin-bottom: 2px;
  }

  &__description {
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    display: block;
  }
}