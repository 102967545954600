.error-alert {
  display: flex;
  align-items: center;
  gap: 12px;
  &__icon {
  }
  &__text {
    color: $color-accent;
  }
}
