.reference-skeleton {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: calc(100% - 186.5px);

  // @media screen and (max-width: 480px) {
  //   height: calc(100% - 196.5px);
  // }

  &__skeleton {
    margin: 0 0 8px 0 !important;
  }
}
