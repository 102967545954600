.meetup-result {
  padding: 24px 18px;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.completed {
    padding-bottom: 8px;
  }

  &__header {
    display: flex;
    justify-content: space-between;

    padding: 0 10px;
  }

  &__title {
    font-weight: 700;
    font-size: 17px;
    color: #263238;
  }

  &__close.MuiButtonBase-root {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #2574d0;
    background-color: transparent;
    padding: 0;
    &:hover {
      color: #2574d0;
      background-color: transparent;
    }
  }

  &__meetup-score {
    padding: 0 10px;
  }

  &__personal-meetup {
    margin-bottom: 24px;
  }

  &__score-title {
    font-weight: 700;
    font-size: 12px;
    color: black;
    opacity: 0.75;
    margin-bottom: 8px;
  }

  &__meetup-score {
    margin-bottom: 16px;
  }

  &__comment-wrapper {
    position: relative;
    padding: 16px 10px;
    background-color: rgba(38, 50, 56, 0.03);
    border-radius: 6px;
    margin-bottom: 16px;
    cursor: pointer;
    transition: 0.12s ease-in-out;
    &:hover {
      background-color: rgba(38, 50, 56, 0.05);
    }
  }

  &__comment-title {
    font-weight: 700;
    font-size: 12px;
    opacity: 0.75;
  }

  &__comment-icon {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  &__comment {
    margin-top: 8px;
    font-weight: 400;
    font-size: 15px;
    color: black;
    @include text-truncate();
  }

  &__feedback-container {
    margin-bottom: auto;
  }

  &__feedback-wrapper {
    position: relative;
    padding: 16px 10px;
    background-color: rgba(38, 50, 56, 0.03);
    border-radius: 6px;
    margin-bottom: 13px;
  }

  &__feedback-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__feedback-title {
    font-weight: 700;
    font-size: 12px;
    opacity: 0.75;
  }

  &__feedback {
    margin-top: 8px;
  }

  &__confirm-btn.MuiButtonBase-root {
    margin-top: 8px;
    width: 100%;
    background-color: #2574d0;
    opacity: 0.75;
    border-radius: 6px;
  }

  &__failure-btn.MuiButtonBase-root {
    background-color: #263238;
    opacity: 0.75;
    border-radius: 6px;
    &:hover {
      background-color: black;
    }
  }
  &__comment-edit-modal.MuiModal-root {
    z-index: 1303;
    overflow: hidden;
    .app-modal__container {
      overflow: hidden;
      padding: 0;
      max-width: 375px;
      height: 660px;
      width: 100%;
      border-radius: 12px;
      @media screen and (max-width: $media-sm) {
        max-width: 100%;
        height: 100%;
        top: 0;
      }
    }
  }

  &__delete-result.MuiButtonBase-root {
    background: transparent;
    color: $color-accent;
    min-height: 36.5px;
    margin-top: 4px;
    &:hover {
      background: transparent;
    }
  }

  &__error {
    font-size: 12px;
    justify-content: center;
    margin-top: 8px;
  }

  &__content {
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
  }
}
