.not-found-page {
  padding-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__title {
    font-size: 72px;

    @media screen and (max-width: $media-xsm) {
      font-size: 42px;
    }
  }
  &__message {
    font-size: 32px;

    @media screen and (max-width: $media-xsm) {
      font-size: 22px;
    }
  }
}